// LogicalName,PrimaryNameAttribute,PrimaryIdAttribute,SchemaName,EntitySetName,Description,DisplayName,MetadataId
export interface EntityDefinition {
  LogicalName: string;
  PrimaryNameAttribute: string;
  PrimaryIdAttribute: string;
  SchemaName: string;
  EntitySetName: string;
  Description: Label;
  DisplayName: Label;
  DisplayCollectionName: Label;
  MetadataId: string;
  IsActivity: boolean;
  IsQuickCreateEnabled: boolean;
  Attributes: Attribute[];
  OneToManyRelationships: IRelationship[];
  ManyToOneRelationships: IRelationship[];
  ManyToManyRelationships: IManyToManyRelationship[];
  PrimaryImageAttribute: string;
  _entityDescriptor: EntityDescriptor;
}

export interface ISimpleEntityDefinition {
  MetadataId: string;
  LogicalName: string;
  DisplayName: string;
}

export interface EntityDescriptor {
  AttributeNames: string[];
}

export interface LookupAttributeMetadataResponse {
  value: LookupAttribute[];
}
export interface LookupAttribute {
  Targets: string[];
}
/**
* Relationship types.
*/
export enum RelationshipType {
  OneToMany = "OneToManyRelationship",
  ManyToMany = "ManyToManyRelationship"
}
export interface IManyToManyRelationship {
  SchemaName: string;
  RelationshipType: RelationshipType;
  Entity1LogicalName: string;
  Entity1IntersectAttribute: string;
  Entity1NavigationPropertyName: string;
  Entity2LogicalName: string;
  Entity2IntersectAttribute: string;
  Entity2NavigationPropertyName: string;
  IntersectEntityName: string;
  IsValidForAdvancedFind: boolean;
}

export interface IRelationship {
  SchemaName: string;
  RelationshipType: RelationshipType;
  ReferencedAttribute: string;
  ReferencedEntity: string;
  ReferencingAttribute: string;
  ReferencingEntity: string;
  ReferencedEntityNavigationPropertyName: string;
  ReferencingEntityNavigationPropertyName: string;
  IsValidForAdvancedFind: boolean;
}

export interface LocalizedLabel {
  Label: string;
  LanguageCode: number;
}

export type FormatType = "DateOnly" | "DateAndTime" | "Text" | "Email" | "None" | "Url" | "Timezone" | "TextArea" | "Regarding" | "Duration" | "Phone" | 'Date and Time' | 'DateAndTime.DateAndTime' | 'datetime';
export type DateTimeBehaviorType = "None" | "UserLocal" | "TimeZoneIndependent" | "DateOnly";

export interface Attribute {
  EntityLogicalName: string;
  LogicalName: string;
  IsPrimaryId: boolean;
  IsPrimaryName: boolean;
  Description: Label;
  DisplayName: Label;
  RequiredLevel: RequiredLevel;
  AttributeOf: string;
  AttributeType: "BigInt" | "Boolean" | "CalendarRules" | "Customer" | "DateTime" | "Decimal" | "Double" | "EntityName" | "Integer" | "Lookup" | "ManagedProperty" | "Memo" | "Money" | "Owner" | "PartyList" | "Picklist" | "State" | "Status" | "String" | "Uniqueidentifier" | "Virtual"
  AttributeTypeName: {
    Value: "MultiSelectPicklistType" | "FileType"
  };
  IsValidForAdvancedFind: {
    Value: boolean,
    CanBeChanged: boolean,
    ManagedPropertyLogicalName: string
  };
  IsValidForGrid: boolean;
  Format?: FormatType,
  FormatName?: {
    Value: FormatType;
  }
  Targets?: string[];
  DateTimeBehavior: {
    Value: DateTimeBehaviorType;
  }
  MaxLength?: number;
  MaxValue?: number;
  Precision?: number;
}

export interface RequiredLevel {
  Value: RequiredLevelValues;
}
export type RequiredLevelValues = "None" | "SystemRequired" | "ApplicationRequired" | "Recommended";

export interface Label {
  LocalizedLabels: LocalizedLabel[];
  UserLocalizedLabel?: LocalizedLabel;
}