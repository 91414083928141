import { isDev } from "../../Functions";
import { getTheme, loadTheme, mergeStyleSets } from "@fluentui/react";
import { buildMetadataUrl, getWebResourceUrl } from "@definitions/MetadataApi";
import { DEFAULT_BODY_MAIN_BACKGROUND_COLOR, FRONTEND_VERSION } from "@src/app/Constants";
import { createBrandVariants, createV9Theme } from "@fluentui/react-migration-v8-v9";
import color from 'color';
import { getControlTheme } from '@talxis/base-controls/dist/utils/Theme';
import { normalizeComponentStyling } from "@talxis/base-controls/dist/utils/Theme";
import { ITheme } from "@talxis/base-controls/dist/interfaces/theme";
import { ThemeDesigner, ThemeType } from "../utilities/ThemeDesigner";

export enum SitemapOrientation {
    Horizontal = 742070000,
    Vertical = 742070001
}
export enum SitemapAlignment {
    Center = 742070000,
    Left = 742070001
}

interface ISitemapParameters {
    horizontalAlignment: SitemapAlignment;
    showHorizontalIcons: boolean;
    veritalAreasAsTabs: boolean;
    orientation: SitemapOrientation;
};
export class Theme {
    private _logoUrl?: string;
    private _ribbonTheme?: ITheme;
    private _navbarTheme?: ITheme;
    private _navigationTheme?: ITheme;
    private _mainTheme: ITheme;
    private _controlV9Theme: ComponentFramework.FluentDesignState;
    private _controlV8Theme: ITheme;
    private _navbarControlV9Theme: ComponentFramework.FluentDesignState;
    private _navbarControlV8Theme: ITheme;
    private _formHeaderControlV9Theme: ComponentFramework.FluentDesignState;
    public bodyBackgroudColor?: string;
    public gradientBackground: boolean;
    public containerWidth: number | undefined;
    public globalNotificationCollapseCount: number;
    public sitemap: ISitemapParameters;

    constructor(entity?: ComponentFramework.WebApi.Entity) {
        this.globalNotificationCollapseCount = entity?.["talxis_globalnotification_collapsecount"] ?? 2;
        this.containerWidth = entity?.["talxis_bodywidth"];
        this._injectCustomCss(entity);
        this._setSitemapParameters(entity);
        this._setThemeParameters(entity);
        this._setLogoUrl(entity);
        this._injectFavicon(entity);
        this._injectCSSVariables();
        loadTheme(this._mainTheme);
    }
    public get ribbon(): ITheme {
        return this._ribbonTheme;;
    }
    public get navigation(): ITheme {
        return this._navigationTheme;
    }
    public get navbar() {
        return this._navbarTheme;
    }
    public get main() {
        return this._mainTheme;
    }
    public get controlV8() {
        return this._controlV8Theme;
    }
    public get controlV9() {
        return this._controlV9Theme;
    }
    public get navbarControlV9() {
        return this._navbarControlV9Theme;
    }
    public get navbarControlV8() {
        return this._navbarControlV8Theme;
    }
    public get formHeaderControlV9() {
        return this._formHeaderControlV9Theme;
    };
    public get logoUrl() {
        return this._logoUrl;
    }

    private _setLogoUrl(entity: any) {
        if (entity?.['talxis_logotooltip']) {
            this._logoUrl = buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_logotooltip/$value`, false, isDev());
            return;
        }
        this._logoUrl = this._navbarTheme.isInverted ? `images/talxis_logo_white.png` : `images/talxis_logo_black.png`;
    }

    private _injectCSSVariables() {
        const variables: {
            [name: string]: string;
        } = {};
        const injectVaribles = (name: ThemeType, theme: ITheme) => {
            for (const [key, value] of Object.entries(theme.semanticColors)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
            for (const [key, value] of Object.entries(theme.palette)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
        };
        injectVaribles(ThemeType.MAIN, this._mainTheme);
        injectVaribles(ThemeType.NAVBAR, this._navbarTheme);
        injectVaribles(ThemeType.RIBBON, this._ribbonTheme);
        injectVaribles(ThemeType.NAVIGATION, this._navigationTheme);
        document.body.classList.add(mergeStyleSets({
            themeVariables: {
                '--talxis-main-bodyBackgroundMain': this.bodyBackgroudColor,
                ...(this.containerWidth && { '--talxis-main-bodyWidth': `${this.containerWidth}px` }),
                ...variables
            }
        }).themeVariables);
    };

    private _injectCustomCss(entity: any) {
        const customCSS = entity?.["talxis_cascadingstylesheets"];
        if (!customCSS) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.setAttribute('id', 'customCSS');
        styleElement.innerHTML = this._replaceWebResourceLinksInCss(customCSS);
        document.head.appendChild(styleElement);
    }

    private _replaceWebResourceLinksInCss(customCSS: string) {
        const generatedCss = customCSS.replace(/: url\(['"]?\/webresources\/(.*)['"]?\)/i, `: url(${getWebResourceUrl(`webresources/$1`)})`);
        return generatedCss;
    }
    private _generateTheme(backgroundColor: string, primaryColor: string, textColor: string, themeType: ThemeType, defaultTheme?: ITheme): ITheme {
        return ThemeDesigner.generateTheme({
            backgroundColor: backgroundColor,
            primaryColor: primaryColor,
            textColor: textColor,
        });
    }
    private _generateControlThemes(baseTheme: ITheme, inputTheme?: {
        inputBackground?: string;
        inputBorder?: string;
        inputBorderHovered?: string;
        inputText?: string;
        inputPlaceholderText?: string;
        primaryColor?: string;
        underlined?: boolean
    }): [ComponentFramework.FluentDesignState, ITheme] {
        const theme = ThemeDesigner.generateTheme({
            backgroundColor: baseTheme.semanticColors.bodyBackground,
            primaryColor: inputTheme.primaryColor ?? baseTheme.palette.themePrimary,
            textColor: baseTheme.semanticColors.bodyText,
        });
        const v9 = createV9Theme(theme);
        const brand = createBrandVariants(theme.palette);

        const fluentDesignState: ComponentFramework.FluentDesignState = {
            brand: brand,
            tokenTheme: { ...v9, ...inputTheme, underlined: inputTheme?.underlined ?? true },
            isDarkTheme: new color(theme.semanticColors.bodyBackground).isDark(),
        };
        const v8 = getControlTheme(fluentDesignState);
        return [fluentDesignState, v8];

    }

    private _injectFavicon(entity: any) {
        let faviconUrl = `images/favicon.ico?v=${FRONTEND_VERSION}`;
        if (entity?.['talxis_faviconlogoid']) {
            const url = new URL(buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_faviconlogoid/$value`, false, isDev()));
            //&talxisFavicon parameter is used so the url does not end with .com since it breaks icon fetching
            url.searchParams.append('talxis_favicon', 'true');
            faviconUrl = url.toString();
        }
        const faviconElement = document.createElement('link');
        faviconElement.setAttribute('rel', 'icon');
        faviconElement.setAttribute('href', faviconUrl);
        document.head.appendChild(faviconElement);
    }

    private _setSitemapParameters(entity: any) {
        this.sitemap = {
            horizontalAlignment: entity?.["talxis_sitemapalignmenttypecode"] ?? SitemapAlignment.Center,
            orientation: entity?.["talxis_sitemaporientationtypecode"] ?? SitemapOrientation.Vertical,
            showHorizontalIcons: entity?.["talxis_showsitemapitemicons"] ?? false,
            veritalAreasAsTabs: entity?.["talxis_areasastabs"]
        };
    }

    private _setThemeParameters(entity: any) {
        //default value set to 2 to follow PowerApps design
        this.gradientBackground = entity?.["talxis_backgroundgradient"];
        //this color refers to the background color of entire page and isn't reflected on surfaces
        this.bodyBackgroudColor = entity?.['talxis_bodybackgroundcolor'] ?? DEFAULT_BODY_MAIN_BACKGROUND_COLOR;
        const baseTheme = getTheme();
        //this applies to the background color of all fluent surfaces (card, callout, panel...)
        //we currently only support white background since a lot of PCF's and portal code expects these surfaces to be white
        //changing this color will break a lot of things, thus why it is currently hardcoded to white
        this._mainTheme = normalizeComponentStyling(this._generateTheme('white', entity?.["talxis_maincolor"] ?? baseTheme.palette.themePrimary, entity?.["talxis_textcolor"] ?? baseTheme.semanticColors.bodyText, ThemeType.MAIN));
        this._ribbonTheme = normalizeComponentStyling(this._generateTheme(entity?.["talxis_ribbonbackgroundcolor"] ?? this._mainTheme.palette.neutralLighter, entity?.["talxis_ribbonprimarycolor"] ?? this._mainTheme.palette.themePrimary, entity?.["talxis_ribbontextcolor"] ?? this._mainTheme.palette.black, ThemeType.RIBBON, this._mainTheme));
        this._navbarTheme = normalizeComponentStyling(this._generateTheme(entity?.["talxis_navbarbackgroundcolor"] ?? this._mainTheme.palette.themePrimary, entity?.["talxis_navbarprimarycolor"] ?? this._mainTheme.palette.white, entity?.["talxis_navbartextcolor"] ?? this._mainTheme.palette.white, ThemeType.NAVBAR, this._mainTheme));
        this._navigationTheme = normalizeComponentStyling(this._generateTheme(entity?.["talxis_navigationbackgroundcolor"] ?? this._mainTheme.palette.neutralLighter, entity?.["talxis_navigationprimarycolor"] ?? this._mainTheme.palette.themePrimary, entity?.["talxis_navigationtextcolor"] ?? this._mainTheme.palette.black, ThemeType.NAVIGATION, this._mainTheme));

        [this._controlV9Theme, this._controlV8Theme] = this._generateControlThemes(this._mainTheme, {
            inputBackground: entity?.["talxis_controlinputbackground"],
            inputBorder: entity?.["talxis_controlinputborder"],
            inputBorderHovered: entity?.["talxis_controlinputborderhovered"],
            inputPlaceholderText: entity?.["talxis_controlinputplaceholdertext"],
            inputText: entity?.["talxis_controlinputtext"],
            primaryColor: entity?.["talxis_controlprimarycolor"],
            underlined: entity?.["talxis_controlinputunderlinedtypecode"],

        });
        [this._navbarControlV9Theme, this._navbarControlV8Theme] = this._generateControlThemes(this._navbarTheme, {
            inputBackground: entity?.["talxis_navbarcontrolinputbackground"],
            inputBorder: entity?.["talxis_navbarcontrolinputborder"],
            inputBorderHovered: entity?.["talxis_navbarcontrolinputborderhovered"],
            inputPlaceholderText: entity?.["talxis_navbarcontrolinputplaceholdertext"],
            inputText: entity?.["talxis_navbarcontrolinputtext"],
            primaryColor: entity?.["talxis_navbarcontrolprimarycolor"],
            underlined: entity?.["talxis_navbarcontrolinputunderlinedtypecode"]
        });
        [this._formHeaderControlV9Theme] = this._generateControlThemes(this._mainTheme, {
            inputPlaceholderText: entity?.["talxis_controlinputplaceholdertext"],
            inputText: entity?.["talxis_controlinputtext"],
            primaryColor: entity?.["talxis_controlprimarycolor"],
            underlined: entity?.["talxis_controlunderlined"],
            inputBackground: this._mainTheme.semanticColors.bodyBackground,
            inputBorder: 'transparent',
            inputBorderHovered: 'transparent',
        });
    }

}