import React, { createContext, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { IFormProps, IFormControlStateValues } from './interfaces';
import { Form as IForm } from './interfaces/form';
import Tabs from './components/Tabs';
import { FormMapper } from '@mappers/FormMapper';
import { IFormContext } from './interfaces/IFormContext';
import { FormControlType, ParticipationTypeMasktoType } from './interfaces/enums';
import { executeFunctionByName, isPromise, sanitizeGuid, formatXrmGuid, isLookupSoft, replaceLast } from '@app/Functions';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { EntityDefinition as IEntityDefinition } from '@app/interfaces/entitydefinition';
import { IChildControl } from "./interfaces/IChildControl";
import { IAttributeConfiguration } from './interfaces/IAttributeConfiguration';
import { getRequiredLevel } from '@ComponentFramework/PropertyClasses/Context';
import { Text } from '@fluentui/react/lib/Text';
import './css/styles.css';
import { Cell } from './components/Cell';
import { useResizeDetector } from 'react-resize-detector';
import { IconButton, mergeStyles, useTheme } from '@fluentui/react';
import { OptionSetDefinition } from '@definitions/OptionSetDefinition';
import * as queryString from 'query-string';
import { history } from '@providers/HistoryProvider/HistoryProvider';
import { QueryData } from '@pages/Control/interfaces';
import { useControlledStateValues, Multistage, StageValue } from '@talxis/react-components';
import { DataType } from '@ComponentFramework/interfaces/DataType';
import cloneDeep from 'lodash/cloneDeep';
import { XrmFormType } from '../../../../Xrm/XrmFormType';
import { LocalizeLabel } from '@localization/helpers';
import { ICreateRequest, IUpdateRequest, WebApiErrors, XrmWebApiException } from '@ComponentFramework/PropertyClasses/WebApi';
import { FormLoading } from '@src/components/loadings/FormLoading/FormLoading';
import { FormType } from '@src/app/classes/definitions/FormDefinition';
import { FormRibbon } from '@src/app/classes/models/Ribbon/FormRibbon';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';
import { IFrameFactory } from '@src/app/classes/utilities/IFrameFactory';
import { ScriptLoader } from '@src/app/classes/loaders/ScriptLoader';
import { SCRIPT_ISOLATION } from '@src/app/Constants';
import { UserSettingsDefinition } from '@src/app/classes/definitions/UserSettingsDefinition';
import isEmail from 'validator/lib/isEmail';

export const FormContext = createContext<IFormContext | null>(null);

class XrmUi implements Xrm.Ui {
    public _tabs: React.MutableRefObject<IForm.Tab[]>;
    public _setTabVisible: (name: string, visible: boolean) => void;
    public _setSectionVisible: (tabName: string, name: string, visible: boolean) => void;
    public _setCellVisible: (tabName: string, sectionName: string, name: string, visible: boolean) => void;
    public _setSectionLabel: (tabName: string, name: string, label: string) => void;
    public _setTabLabel: (name: string, label: string) => void;
    public _setTabFocus: (id: string) => void;
    public _stateValuesRef: React.MutableRefObject<IFormControlStateValues>;
    private _formContext: XrmFormContext;
    private _formNotifications: React.MutableRefObject<IFormNotification[]>;
    private _setFormNotifications: (notifications: IFormNotification[]) => void;
    private _refreshRibbon: () => void;
    private _isDialog: boolean;

    constructor(
        tabs: React.MutableRefObject<IForm.Tab[]>,
        setTabVisible: (name: string, visible: boolean) => void,
        setSectionVisible: (tabName: string, name: string, visible: boolean) => void,
        setCellVisible: (tabName: string, sectionName: string, name: string, visible: boolean) => void,
        setSectionLabel: (tabName: string, name: string, label: string) => void,
        setTabLabel: (name: string, label: string) => void,
        setTabFocus: (id: string) => void,
        stateValuesRef: React.MutableRefObject<IFormControlStateValues>,
        formContext: XrmFormContext,
        formNotifications: React.MutableRefObject<IFormNotification[]>,
        setFormNotifications: (notifications: IFormNotification[]) => void,
        refreshRibbon: () => void,
        isDialog?: boolean
    ) {
        this._formContext = formContext;
        // TODO: Maybe we don't need to keep the entire tabs object here
        this._tabs = tabs;
        this._setTabVisible = setTabVisible;
        this._setSectionVisible = setSectionVisible;
        this._setCellVisible = setCellVisible;
        this._setSectionLabel = setSectionLabel;
        this._setTabLabel = setTabLabel;
        this._setTabFocus = setTabFocus;
        this._stateValuesRef = stateValuesRef;
        this._formNotifications = formNotifications;
        this._setFormNotifications = setFormNotifications;
        this._refreshRibbon = refreshRibbon;
        this._isDialog = isDialog;
        let that = this;
        this.tabs = {
            get(arg1?: any): any {
                if (arg1 && typeof arg1 === "string") {
                    const tab = that._tabs.current.find(x => x.name == arg1);
                    if (!tab) return null;
                    return that._mapTabObject(tab);
                }
                else if (!arg1) {
                    return that._tabs.current.map(x => that._mapTabObject(x));
                }
            },
            forEach(delegate: Xrm.Collection.IterativeDelegate<Xrm.Controls.Tab>): void {
                throw new Error("Not implemented!");
            },
            getLength(): number {
                return that._tabs.current.length;
            }
        };
    }

    // TODO: We should do all lookups in realtime in _tabs object so we prevent stale values
    private _mapTabObject(tab: IForm.Tab): Xrm.Controls.Tab {
        let that = this;
        return {
            setVisible: (visible: boolean): void => {
                that._setTabVisible(tab.name, visible);
            },
            getVisible: (): boolean => {
                const found = that._tabs.current.find(x => x.name == tab.name);
                return found.visible;
            },
            setLabel(label: string): void {
                that._setTabLabel(tab.name, label);
            },
            setFocus: (): void => {
                that._setTabFocus(tab.id);
            },
            getName: (): string => {
                return tab.name;
            },
            sections: {
                get(arg1?: any): Xrm.Controls.Section | Xrm.Controls.Section[] {
                    if (arg1 && typeof arg1 === "string") {
                        const foundTab = that._tabs.current.find(x => x.name == tab.name);
                        const section = foundTab?.columns?.flatMap(x => x.sections)?.find(y => y.name === arg1);
                        if (!section) return null;
                        return that._mapSectionObject(tab.name, section);
                    }
                    else {
                        const foundTab = that._tabs.current.find(x => x.name == tab.name);
                        const sections = foundTab?.columns?.flatMap(x => x.sections?.map(y => that._mapSectionObject(tab.name, y)));
                        return sections;
                    }
                }
            },
            getDisplayState: (): Xrm.DisplayState => {
                if (that._stateValuesRef.current.currentTabId === tab.id) {
                    return "expanded";
                }
                else {
                    return "collapsed";
                }
            }
        } as Xrm.Controls.Tab;
    }

    // TODO: We should do all lookups in realtime in _tabs object so we prevent stale values
    private _mapSectionObject(tabName: string, section: IForm.Section): Xrm.Controls.Section {
        let that = this;
        return {
            getName: (): string => {
                return section.name;
            },
            setLabel: (label: string) => {
                that._setSectionLabel(tabName, section.name, label);
            },
            setVisible: (visible: boolean): void => {
                that._setSectionVisible(tabName, section.name, visible);
            },
            controls: {
                get(arg1?: any): Xrm.Controls.Control | Xrm.Controls.Control[] {
                    if (arg1 && typeof arg1 === "string") {
                        const foundTab = that._tabs.current.find(x => x.name === tabName);
                        const foundSection: IForm.Section = foundTab?.columns?.flatMap(x => x.sections)?.find(x => x.name === section.name);
                        const control = foundSection?.rows?.flatMap(x => x.cells)?.find(x => x.control.id === arg1);
                        return that._mapCellObject(tabName, section.name, control);
                    }
                    else {
                        const foundTab = that._tabs.current.find(x => x.name === tabName);
                        const foundSection = foundTab?.columns?.flatMap(x => x.sections)?.find(y => y.name == section.name);
                        const controls = foundSection?.rows?.flatMap(x => x.cells)?.map(x => that._mapCellObject(tabName, section.name, x));
                        return controls;
                    }
                }
            }
        } as Xrm.Controls.Section;
    }

    // TODO: We should do all lookups in realtime in _tabs object so we prevent stale values
    private _mapCellObject(tabName: string, sectionName: string, cell: IForm.Cell): Xrm.Controls.StandardControl {
        let that = this;
        return {
            getName: (): string => {
                return cell.control.id;
            },
            setVisible: (visible: boolean): void => {
                that._setCellVisible(tabName, sectionName, cell.control.id, visible);
            }
        } as Xrm.Controls.StandardControl;
    }

    setFormNotification(message: string, level: Xrm.FormNotificationLevel, uniqueId: string): boolean {
        this._formNotifications.current.push({
            message: message,
            level: level,
            uniqueId: uniqueId
        });
        this._setFormNotifications([...this._formNotifications.current]);

        return true;
    }
    clearFormNotification(uniqueId: string): boolean {
        this._formNotifications.current = this._formNotifications.current.filter(x => x.uniqueId !== uniqueId);
        this._setFormNotifications([...this._formNotifications.current]);

        return true;
    }
    close(): void {
        if (this._isDialog) {
            //TODO: add form name as param 
            // Set isNewRecord to true for dialogs. Dialog is detected by not being bound to entity
            window.TALXIS.Portal.Context.closeFormDialog(null, this._formContext._entity, null, this._formContext.data.entity.getEntityName() === undefined || this._formContext._isDialogNewRecord);
        } else {
            history.goBack();
        }
    }
    getFormType(): XrmEnum.FormType {
        if (this._formContext.data?.entity.getEntityName() === undefined) { return XrmFormType.Dialog as any; }
        else if (!this._formContext.data.entity?.getId()) { return XrmFormType.Create as any; }
        else if (this._formContext.data.entity?.getId() && this._formContext?.getAttribute('statecode')?.getValue() !== 0) { return XrmFormType.Disabled as any; }
        else if (this._formContext.data.entity?.getId()) { return XrmFormType.Update as any; }
        return XrmFormType.Undefined as any;
    }
    getViewPortHeight(): number {
        throw new Error("Not implemented!");
    }
    getViewPortWidth(): number {
        throw new Error("Not implemented!");
    }
    refreshRibbon(refreshAll?: boolean): void {
        this._refreshRibbon();
    }
    setFormEntityName(name: string): void {
        throw new Error("Not implemented!");
    }
    process: Xrm.Controls.ProcessControl;
    controls: Xrm.Collection.ItemCollection<Xrm.Controls.Control>;
    formSelector: Xrm.Controls.FormSelector = {
        getCurrentItem: () => {
            return {
                getId: () => {
                    return this._formContext._formId;
                },
                getLabel() {
                    throw new Error("Not implemented!");
                },
                navigate() {
                    throw new Error("Not implemented!");
                },
                getVisible() {
                    throw new Error("Not implemented!");
                },
                setVisible() {
                    throw new Error("Not implemented!");
                }
            };
        },
        items: null
    };
    navigation: Xrm.Controls.Navigation;
    tabs: Xrm.Collection.ItemCollection<Xrm.Controls.Tab>;
    quickForms: Xrm.Collection.ItemCollection<Xrm.Controls.QuickFormControl>;
    footerSection: undefined;
    headerSection: undefined;
}

export class XrmFormContext implements Xrm.FormContext {
    private _entityName: string;
    public _entityId: string;
    public _entity: ComponentFramework.WebApi.Entity = null;
    public _originalEntity: ComponentFramework.WebApi.Entity = null;
    public _childFormContextsRef: React.MutableRefObject<{ [datafieldname: string]: Xrm.FormContext; }>;
    public _tabs: React.MutableRefObject<IForm.Tab[]> = null;
    public _header: React.MutableRefObject<IForm.Header> = null;
    public _setEntityValue: (key: string, value: any) => void;
    public _setTabs: (tabs: IForm.Tab[]) => void;
    public _formId: string;
    public _saveForm: () => Promise<void | Xrm.CreateResponse>;
    public _refreshForm: () => Promise<void>;
    public _childControlsRef: React.MutableRefObject<{ [name: string]: IChildControl }>;
    public _onSaveRef: React.MutableRefObject<((context: Xrm.Events.SaveEventContext) => Promise<void> | void)[]>;
    public _onPostSaveRef: React.MutableRefObject<Xrm.Events.ContextSensitiveHandler[]>;
    public _attributeConfigurationRef: React.MutableRefObject<{ [name: string]: IAttributeConfiguration }>;
    public _setRequiredLevel: (attributeName: string, level: Xrm.Attributes.RequirementLevel) => void;
    public _entityDefinitionRef: React.MutableRefObject<IEntityDefinition>;
    public _entityChangesRef: React.MutableRefObject<ComponentFramework.WebApi.Entity>;
    private _isFormValid: () => boolean;
    public _updateAttributeConfiguration: () => void;
    public _isDialogNewRecord: boolean = false;
    private _setShouldRefresh: (shouldRefresh: number) => void;
    private _isFieldValid: (control: IForm.Control) => boolean;
    public pageId: string;

    public static isTalxisPortal: boolean = true;

    constructor(
        pageId: string,
        formId: string,
        tabs: React.MutableRefObject<IForm.Tab[]>,
        header: React.MutableRefObject<IForm.Header>,
        setEntityValue: (key: string, value: any) => void,
        setTabVisible: (name: string, visible: boolean) => void,
        setSectionVisible: (tabName: string, name: string, visible: boolean) => void,
        setCellVisible: (tabName: string, sectionName: string, name: string, visible: boolean) => void,
        setSectionLabel: (tabName: string, name: string, label: string) => void,
        setTabLabel: (name: string, label: string) => void,
        setTabFocus: (id: string) => void,
        setTabs: (tabs: IForm.Tab[]) => void,
        saveForm: () => Promise<void | Xrm.CreateResponse>,
        refreshForm: () => Promise<void>,
        childControlsRef: React.MutableRefObject<{ [name: string]: IChildControl }>,
        onPostSaveRef: React.MutableRefObject<Xrm.Events.ContextSensitiveHandler[]>,
        onSaveRef: React.MutableRefObject<((context: Xrm.Events.SaveEventContext) => Promise<void> | void)[]>,
        stateValuesRef: React.MutableRefObject<IFormControlStateValues>,
        attributeConfigurationRef: React.MutableRefObject<{ [name: string]: IAttributeConfiguration }>,
        entityDefinitionRef: React.MutableRefObject<IEntityDefinition>,
        entityChangesRef: React.MutableRefObject<ComponentFramework.WebApi.Entity>,
        setRequiredLevel: (attributeName: string, level: Xrm.Attributes.RequirementLevel) => void,
        isFormValid: () => boolean,
        updateAttributeConfiguration: () => void,
        childFormContextsRef: React.MutableRefObject<{ [datafieldname: string]: Xrm.FormContext; }>,
        formNotifications: React.MutableRefObject<IFormNotification[]>,
        setFormNotifications: (notifications: IFormNotification[]) => void,
        refreshRibbon: () => void,
        setShouldRefresh: (shouldRefresh: number) => void,
        isFieldValid: (control: IForm.Control) => boolean,
        entityName?: string,
        entityId?: string,
        isDialog?: boolean
    ) {
        this._entityName = entityName;
        this._entityId = entityId;
        this._setEntityValue = setEntityValue;
        this._setTabs = setTabs;
        this._saveForm = saveForm;
        this._refreshForm = refreshForm;
        this._tabs = tabs;
        this._header = header;
        this._formId = formId;
        this._childControlsRef = childControlsRef;
        this._onPostSaveRef = onPostSaveRef;
        this._attributeConfigurationRef = attributeConfigurationRef;
        this._onSaveRef = onSaveRef;
        this._setRequiredLevel = setRequiredLevel;
        this._isFormValid = isFormValid;
        this._entityDefinitionRef = entityDefinitionRef;
        this._entityChangesRef = entityChangesRef;
        this.ui = new XrmUi(tabs, setTabVisible, setSectionVisible, setCellVisible, setSectionLabel, setTabLabel, setTabFocus, stateValuesRef, this, formNotifications, setFormNotifications, refreshRibbon, isDialog);
        this._updateAttributeConfiguration = updateAttributeConfiguration;
        this._childFormContextsRef = childFormContextsRef;
        this._setShouldRefresh = setShouldRefresh;
        this._isFieldValid = isFieldValid;
        this.pageId = pageId;
    }

    // TODO: This shouldn't be any, but Xrm.Data
    public data: any = {
        getIsDirty: (): boolean => {
            //check if child forms have unsaved changes
            for (const datafieldname of Object.keys(this._childFormContextsRef.current)) {
                const formContext = this._childFormContextsRef.current[datafieldname];
                if (formContext.data.getIsDirty()) {
                    return true;
                }
            }
            let hasChanges = false;
            for (const [property, value] of Object.entries(this._entityChangesRef?.current)) {
                let originalValue = this._originalEntity[property];
                if (
                    // Lookups are stored as arrays of LookupValue, so we need to do deep comparison for proper change detection
                    (originalValue != value && (!Array.isArray(value) || !Array.isArray(originalValue))) ||
                    (Array.isArray(value) && Array.isArray(originalValue) && (
                        value.filter((val1: ComponentFramework.LookupValue) => !originalValue.find((val2: ComponentFramework.LookupValue) => val1.id === val2.id)).length > 0 ||
                        originalValue.filter((val1: ComponentFramework.LookupValue) => !value.find((val2: ComponentFramework.LookupValue) => val1.id === val2.id)).length > 0))
                ) {
                    const field = this._entityDefinitionRef.current?.Attributes.find(x => x.LogicalName === property);

                    if (field && field.AttributeType === "DateTime") {
                        if (new Date(originalValue).getTime() === new Date(value).getTime()) {
                            break;
                        }
                    }
                    if (field && field.AttributeTypeName.Value === "FileType") {
                        break;
                    }
                    hasChanges = true;
                    break;
                }
            }
            if (Object.keys(this._entityChangesRef?.current ?? {}).length === 0 || !hasChanges) {
                return false;
            }
            else {
                return true;
            }
        },
        attributes: {
            get: (attributeName: string) => {
                return this.getAttribute(attributeName);
            }
        },
        entity: {
            getEntityName: () => {
                return this._entityName;
            },
            addOnPostSave: (handler: Xrm.Events.ContextSensitiveHandler): void => {
                this._onPostSaveRef.current.push(handler);
            },
            removeOnPostSave: (handler: Xrm.Events.ContextSensitiveHandler): void => {
                const found = this._onPostSaveRef.current.indexOf(handler);
                if (found !== -1) {
                    this._onPostSaveRef.current.splice(found, 1);
                }
            },
            addOnSave: (handler: Xrm.Events.ContextSensitiveHandler): void => {
                this._onSaveRef.current.push(handler);
            },
            removeOnSave: (handler: Xrm.Events.ContextSensitiveHandler): void => {
                const found = this._onSaveRef.current.indexOf(handler);
                if (found !== -1) {
                    this._onSaveRef.current.splice(found, 1);
                }
            },
            isValid: (): boolean => {
                return this._isFormValid();
            },
            getId: (): string => {
                return this._entityId;
            },
            getPrimaryAttributeValue: () => {
                return this._entity[this._entityDefinitionRef.current.PrimaryNameAttribute];
            }
        },
        save: async (saveOptions?: Xrm.SaveOptions): Promise<void | Xrm.CreateResponse> => {
            return this._saveForm();
        },
        refresh: async (save: boolean): Promise<void> => {
            if (save) {
                const result = await this._saveForm();
                if (!result) {
                    console.warn("Skipping form refresh, because save failed.");
                    return;
                }
            }
            await this._refreshForm();
        },
        isValid: (): boolean => {
            return this._isFormValid();
        }
    };
    public ui: Xrm.Ui = null;

    public getAttribute(): Xrm.Attributes.Attribute[];
    public getAttribute<T extends Xrm.Attributes.Attribute>(attributeName: string): T;
    public getAttribute(attributeName: string): Xrm.Attributes.Attribute;
    public getAttribute(index: number): Xrm.Attributes.Attribute;
    public getAttribute(delegateFunction: Xrm.Collection.MatchingDelegate<Xrm.Attributes.Attribute>): Xrm.Attributes.Attribute[];
    public getAttribute(arg1?: any): any {
        if (typeof arg1 === "string") {
            // @ts-ignore - _internalDefinition is portal only
            const control = this.getControl().find(x => x._internalDefinition.datafieldname === arg1);
            return {
                // https://learn.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/attributes/getattributetype
                getAttributeType: (): Xrm.Attributes.AttributeType => {
                    const attribute = this._entityDefinitionRef.current.Attributes.find(x => x.LogicalName === arg1);

                    if (attribute?.AttributeTypeName?.Value === "MultiSelectPicklistType") return "multiselectoptionset";

                    switch (attribute?.AttributeType) {
                        case 'Boolean':
                        case 'ManagedProperty':
                            return 'boolean';
                        case 'DateTime':
                            return 'datetime';
                        case 'Decimal':
                            return 'decimal';
                        case 'Double':
                            return 'double';
                        case 'BigInt':
                        case 'Integer':
                            return 'integer';
                        case 'Customer':
                        case 'Lookup':
                        case 'Owner':
                        case 'PartyList':
                            return 'lookup';
                        case 'Memo':
                            return 'memo';
                        case 'Money':
                            return 'money';
                        case 'Picklist':
                        case 'State':
                        case 'Status':
                            return 'optionset';
                        default:
                            return 'string';
                    }
                },
                getOptions: () => {
                    if (this._entityDefinitionRef.current &&
                        (
                            this._entityDefinitionRef.current.Attributes.find(x => x.LogicalName === arg1)?.AttributeType === "Picklist" ||
                            this._entityDefinitionRef.current.Attributes.find(x => x.LogicalName === arg1)?.AttributeTypeName?.Value === "MultiSelectPicklistType"
                        )
                    ) {
                        const allOptions = this._attributeConfigurationRef.current[arg1]?.options;
                        const options: Xrm.OptionSetValue[] = [];
                        for (const option of allOptions) {
                            options.push({
                                text: option.Label.UserLocalizedLabel.Label,
                                value: option.Value
                            });
                        }
                        return options;
                    }
                    else {
                        throw new Error("Not implemented, target attribute is not OptionSet!");
                    }
                },
                // https://learn.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/attributes/getvalue
                getValue: () => {
                    const attribute = this._entityDefinitionRef.current?.Attributes.find(x => x.LogicalName === arg1);
                    if (
                        (attribute && (attribute.AttributeType === "Lookup" || attribute.AttributeType === "Customer" || attribute.AttributeType === "Owner")) ||
                        // This handles case of dialogs where we currently don't have any attribute configuration set yet
                        (this._entityDefinitionRef.current === null && isLookupSoft(this._entity[arg1]))
                    ) {
                        const value = this._entity[arg1];
                        if (!value || !Array.isArray(value)) {
                            return value;
                        }
                        else {
                            const lookupValue: Xrm.LookupValue[] = cloneDeep(value);
                            lookupValue.forEach(x => x.id = formatXrmGuid(x.id));
                            return lookupValue;
                        }
                    }
                    else if (attribute && attribute.AttributeType === "DateTime") {
                        if (this._entity[arg1] === null || this._entity[arg1] === undefined || this._entity[arg1] === "") return null;
                        let date = new Date(this._entity[arg1]);
                        if (attribute.DateTimeBehavior.Value === "TimeZoneIndependent" || attribute.DateTimeBehavior.Value === "DateOnly") {
                            const offset = UserSettingsDefinition.getUserSettings().getLocalTimeZoneOffset();
                            date = new Date(date.getTime() + offset);
                        }
                        else if (attribute.DateTimeBehavior.Value === "UserLocal" && attribute.Format === "DateOnly") {
                            let offset = UserSettingsDefinition.getUserSettings().getLocalTimeZoneOffset();

                            if (offset < -1) {
                                offset *= -1;
                            }

                            date = new Date(date.getTime() + offset);
                            date.setHours(0);
                        }

                        return date;
                    }
                    else if (attribute && attribute.AttributeTypeName.Value === "MultiSelectPicklistType") {
                        // this already is an array of numbers
                        return this._entity[arg1];
                    }
                    else if (attribute && (attribute.AttributeType === "State" || attribute.AttributeType === "Status" || attribute.AttributeType === "Picklist")) {
                        return Number.parseInt(this._entity[arg1]);
                    }
                    else {
                        return this._entity[arg1];
                    }
                },
                setValue: (value: any) => {
                    // TODO: Check type of value, if it matches well-known formats else transform it to string, due to: https://dev.azure.com/thenetworg/INT0006/_git/TALXIS?path=%2Fsrc%2FAreas%2FDistribution%2FSales%2FApps.Default%2FTS%2Ftalxis_opportunityheader%2FDialogs.ts&version=GBdevelop&line=11&lineEnd=12&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
                    this._setEntityValue(arg1, value);
                },
                setRequiredLevel: (level: Xrm.Attributes.RequirementLevel) => {
                    this._attributeConfigurationRef.current[arg1].customRequiredLevel = level;
                    this._setRequiredLevel(arg1, level);
                    this._updateAttributeConfiguration();
                },
                getRequiredLevel: (): Xrm.Attributes.RequirementLevel => {
                    switch (getRequiredLevel(this._attributeConfigurationRef.current[arg1])) {
                        case 1:
                        case 2:
                            return "required";
                        case 3:
                            return "recommended";
                        case -1:
                        case 0:
                        default:
                            return "none";

                    }
                },
                getName: () => {
                    return arg1;
                },
                setIsValid: (isValid: boolean, message?: string) => {
                    this._attributeConfigurationRef.current[arg1].isValid = isValid;
                    this._attributeConfigurationRef.current[arg1].isValidMessage = message;
                    this._setShouldRefresh(Math.random());
                },
                isValid: () => {
                    // @ts-ignore = _internalDefinition is portal only
                    return this._isFieldValid(control._internalDefinition);
                }
            };
        }

        throw new Error("Not implemented, reference attribute by its name!");
    }

    private _findControl(tabs: IForm.Tab[], header: IForm.Header, id?: string): IForm.Control[] {
        // TODO: Can we do this nicer? Use JPath
        const controls: IForm.Control[] = [];
        if (header) {
            for (const rows of header?.rows) {
                for (const cell of rows?.cells) {
                    if (id) {
                        if (cell?.control?.id === id) {
                            return [cell.control];
                        }
                    } else if (cell.control) {
                        controls.push(cell.control);
                    }
                }
            }
        }
        for (const tab of tabs) {
            for (const column of tab.columns) {
                for (const section of column.sections) {
                    for (const row of section.rows) {
                        for (const cell of row.cells) {
                            if (id) {
                                if (cell?.control?.id === id) {
                                    return [cell.control];
                                }
                            } else if (cell.control) {
                                controls.push(cell.control);
                            }
                        }
                    }
                }
                for (const row of tab.tabFooter?.rows ?? []) {
                    for (const cell of row.cells) {
                        if (id) {

                            if (cell?.control?.id === id) {
                                return [cell.control];
                            }
                        } else if (cell.control) {
                            controls.push(cell.control);
                        }
                    }
                }
            }
        }

        return controls;
    }

    private _findCell(tabs: IForm.Tab[], header: IForm.Header, id: string): IForm.Cell {
        for (const row of header?.rows ?? []) {
            for (const cell of row?.cells) {
                if (cell?.control?.id === id) {
                    return cell;
                }
            }
        }
        // TODO: Can we do this nicer? Use JPath
        for (const tab of tabs) {
            for (const column of tab.columns) {
                for (const section of column.sections) {
                    for (const row of section.rows) {
                        for (const cell of row.cells) {
                            if (cell?.control?.id === id) {
                                return cell;
                            }
                        }
                    }
                }
                for (const row of tab.tabFooter?.rows ?? []) {
                    for (const cell of row.cells) {
                        if (cell?.control?.id === id) {
                            return cell;
                        }
                    }
                }
            }
        }

        return null;
    }

    private _composeControlDefinition(that: this, arg1: string, control: IForm.Control): Partial<Xrm.Controls.StandardControl> {
        return {
            _internalDefinition: control,
            getVisible: () => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                return control.visible;
            },
            setVisible: (visible: boolean) => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                console.log(`Setting ${control.id} control to visible: ${visible}.`);
                // Update by reference
                control.visible = visible;
                this._setTabs(this._tabs.current);
            },
            getDisabled: () => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                return control.disabled;
            },
            setDisabled: (disabled: boolean) => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                // Update by reference
                control.disabled = disabled;
                console.log(`Setting ${control.id} control to disabled: ${disabled}.`);
                // rerender tabs
                this._setTabs(this._tabs.current);
            },
            getLabel: () => {
                const cell = that._findCell(that._tabs.current, that._header.current, arg1);
                return cell.label;
            },
            setLabel: (label: string) => {
                const cell = that._findCell(that._tabs.current, that._header.current, arg1);
                cell.label = label;
                // rerender tabs
                this._setTabs(this._tabs.current);
            },
            getAttribute: () => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                return {
                    getValue: () => {
                        return this.getAttribute(control.datafieldname).getValue();
                    },
                    setValue: (value: any) => {
                        this.getAttribute(control.datafieldname).setValue(value);
                    },
                    setRequiredLevel: (level: Xrm.Attributes.RequirementLevel) => {
                        this.getAttribute(control.datafieldname).setRequiredLevel(level);
                    },
                    getRequiredLevel: () => {
                        return this.getAttribute(control.datafieldname).getRequiredLevel();
                    }
                } as Xrm.Attributes.Attribute;
            },
            refresh: () => {
                const control = that._childControlsRef.current[arg1];
                if (control) {
                    control.gridRefresh();
                }
                else {
                    throw new Error(`Control with ${arg1} name not found in registered childControls!`);
                }
            },
            addOption: (option: Xrm.OptionSetValue, index?: number) => {
                this._attributeConfigurationRef.current[arg1].options.splice(index ?? this._attributeConfigurationRef.current[arg1].options.length, 0, {
                    Description: {
                        LocalizedLabels: [],
                        UserLocalizedLabel: null
                    },
                    Value: option.value,
                    Label: {
                        LocalizedLabels: [{
                            Label: option.text,
                            LanguageCode: 1033
                        }],
                        UserLocalizedLabel: null
                    }
                });
                this._updateAttributeConfiguration();
            },
            clearOptions: () => {
                this._attributeConfigurationRef.current[arg1].options = [];
                this._updateAttributeConfiguration();
            },
            removeOption: (value: number) => {
                const index = this._attributeConfigurationRef.current[arg1].options?.findIndex(x => x.Value === value);
                this._attributeConfigurationRef.current[arg1].options?.splice(index, 1);
                this._updateAttributeConfiguration();
            },
            getOptions: () => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                const attribute = this.getAttribute(control.datafieldname);
                if (attribute?.getAttributeType() === "optionset" || attribute?.getAttributeType() === "multiselectoptionset") {
                    return (attribute as Xrm.Attributes.OptionSetAttribute).getOptions();
                }
            },
            setDefaultView: (viewId: string) => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                if (control?.xrmType === "lookup") {
                    this._attributeConfigurationRef.current[arg1]!.defaultViewId = sanitizeGuid(viewId);
                }
                else {
                    throw new Error("You can't setDefaultView to a control that's not a lookup!");
                }
            },
            setShowTime: (value: boolean) => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                if (control?.bindings?.Format) {
                    control.bindings.Format.value = value ? "DateAndTime" : "Date";
                } else {
                    control.bindings.Format = { isStatic: true, value: value ? "DateAndTime" : "Date" };
                }
            },
            getShowTime: (): boolean => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                const format = control.bindings?.Format?.value;
                return format === "DateAndTime";
            },
            getControlType: (): Xrm.Controls.ControlType => {
                const [control] = that._findControl(that._tabs.current, that._header.current, arg1);
                return control.xrmType;
            },
            controlDescriptor: {
                ClassId: {
                    guid: (control.classId ? sanitizeGuid(control.classId) : null)
                }
            }
        } as Partial<Xrm.Controls.StandardControl>;
    }

    public getControl(): Xrm.Controls.Control[];
    public getControl<T extends Xrm.Controls.Control>(controlName: string): T;
    public getControl(controlName: string): Xrm.Controls.Control;
    public getControl<T extends Xrm.Controls.Control>(index: number): T;
    public getControl(index: number): Xrm.Controls.Control;
    public getControl(delegateFunction: Xrm.Collection.MatchingDelegate<Xrm.Controls.Control>): Xrm.Controls.Control[];
    public getControl(arg1?: any): any {
        if (typeof arg1 === "string") {
            const that = this;
            const [control] = this._findControl(that._tabs.current, that._header.current, arg1);
            if (!control) return null;

            return this._composeControlDefinition(that, arg1, control);
        } else if (arg1 === undefined) {
            const that = this;
            const controls = this._findControl(that._tabs.current, that._header.current);
            if (controls.length === 0) return null;
            return controls.map((control) => this._composeControlDefinition(that, control.id, control));
        }

        throw new Error("Not implemented, reference control by its controlName!");
    }
}

export interface XrmEventContext extends Xrm.Events.EventContext {
    formContext?: XrmFormContext;
}

export interface IFormNotification {
    message: string;
    level: Xrm.FormNotificationLevel;
    uniqueId: string;
}

const checkFieldDataTypeValidity = (dataType: DataType, value: any): boolean => {
    switch (dataType) {
        case DataType.Currency:
        case DataType.WholeNone:
        case DataType.WholeDuration:
        case DataType.Decimal: {
            if (typeof value === 'number') {
                return true;
            }
            //allow empty values (field is not required at this point)
            if (!value) {
                return true;
            }
            return false;
        }
        case DataType.DateAndTimeDateAndTime:
        case DataType.DateAndTimeDateOnly:
        case DataType.DateAndTime: {
            if (value instanceof Date) {
                return true;
            }
            //allow empty values (field is not required at this point)
            if (!value) {
                return true;
            }
            return false;
        }
        case DataType.SingleLineMail: {
            if (isEmail(value)) {
                return true;
            }
            //allow empty values (field is not required at this point)
            if (!value) {
                return true;
            }
            return false;
        }
    }
    return true;
};

export const isFieldValid = (isRequired: ComponentFramework.PropertyHelper.Types.RequiredLevel, isDisabled: boolean, isVisible: boolean, type: DataType, entity: ComponentFramework.WebApi.Entity, primaryBoundField: string, isValid: boolean) => {
    if ((isRequired === 1 || isRequired === 2) && !isDisabled && isVisible &&
        (
            (type !== "Lookup.Simple" && (
                entity[primaryBoundField] === null ||
                entity[primaryBoundField] === undefined ||
                entity[primaryBoundField] === "" ||
                !checkFieldDataTypeValidity(type, entity[primaryBoundField])
            )) ||
            // Support for lookup values until we properly represent lookups
            (type === "Lookup.Simple" &&
                (entity[primaryBoundField] as ComponentFramework.LookupValue[]) === null ||
                (entity[primaryBoundField] as ComponentFramework.LookupValue[]) === undefined ||
                (entity[primaryBoundField] as ComponentFramework.LookupValue[])?.length === 0)
        )
    ) {
        return false;
    }
    if (!checkFieldDataTypeValidity(type, entity[primaryBoundField])) {
        return false;
    }

    if (isValid === false) {
        return false;
    }

    return true;
};

export const Form: React.FC<IFormProps> = (props) => {
    const [form, setForm] = useState<IForm.Root>(null);
    const [tabs, setTabs] = useState<IForm.Tab[]>(null);
    const [currentTab, setCurrentTab] = useState<IForm.Tab>(null);
    const [error, setError] = useState<Error>(null);
    const [formUpdated, setFormUpdated] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    // This is used to force re-render of form, for example from Xrm when setIsValid is set
    const [shouldRefresh, setShouldRefresh] = useState<number>(0);
    const [attributeConfiguration, setAttributeConfiguration] = useState<{ [name: string]: IAttributeConfiguration }>({});
    const [validate, setValidate] = useState<boolean>(false);
    const theme = useTheme();
    const [entity, setEntity] = useState<ComponentFramework.WebApi.Entity>(null);
    const [entityChanges, setEntityChanges] = useState<ComponentFramework.WebApi.Entity>({});
    const [entityId, setEntityId] = useState<string>(props.entityId);
    const [visitedSteps, setVisitedSteps] = useState<string[]>([]);
    const formRef = useRef<IForm.Root>(null);
    const childFormContextsRef = useRef<{
        [datafieldname: string]: Xrm.FormContext;
    }>({});
    const stateValuesRef = useControlledStateValues<IFormControlStateValues>({
        ...props.state,
        currentTabId: !props.isDialog ? props.state.currentTabId : null,
        previousTabId: null
    }, {
        currentTabId: null,
        previousTabId: null
    }, props.mode);
    const xrmExecutionContextRef = useRef<XrmEventContext>(null);
    const previousEntityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});
    const entityRef = useRef<ComponentFramework.WebApi.Entity>({});
    const entityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});
    const onLoadExecutedRef = useRef<boolean>(false);
    const tabsRef = useRef<IForm.Tab[]>(null);
    const headerRef = useRef<IForm.Header>(null);
    const entityDefinitionRef = useRef<IEntityDefinition>(null);
    const childControlsRef = useRef<{ [name: string]: IChildControl }>({});
    const onPostSaveRef = useRef<Xrm.Events.ContextSensitiveHandler[]>([]);
    const onSaveRef = useRef<((context: Xrm.Events.SaveEventContext) => Promise<void> | void)[]>([]);
    const attributeConfigurationRef = useRef<{ [name: string]: IAttributeConfiguration }>({});
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const formNotificationsRef = useRef<IFormNotification[]>([]);
    const [formNotifications, setFormNotifications] = useState<IFormNotification[]>([]);
    const paramsData = queryString.parse(window.location.search.substring(1))?.data as string;
    const data: QueryData = (paramsData) ? JSON.parse(paramsData) : null;
    const isPortalPage = data?.entityName === 'talxis_portalpage';
    const ribbonRef = useRef<FormRibbon>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const pageIdRef = useRef<string>(window.self.crypto.randomUUID());

    const onResize = useCallback((width: number) => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
            return;
        }
        setIsMobile(false);
    }, []);

    const { ref } = useResizeDetector({ onResize });

    // TODO: IFormProps should have QuickForms input and parsing should be done in the code itself: "<QuickForms><QuickFormIds><QuickFormId entityname="account">E9388EE6-C985-4F4C-9B2A-BEB5044FE3AC</QuickFormId></QuickFormIds></QuickForms>"
    /*
    <property name="value" display-name-key="value_name" of-type-group="lookup" usage="bound" required="true" hidden="true" />
    <property name="QuickForms" required="true" display-name-key="forms_name" usage="input" of-type="SingleLine.Text" />
    */

    useEffect(() => {
        if (xrmExecutionContextRef.current != null) {
            const formContext = xrmExecutionContextRef.current.getFormContext() as XrmFormContext;
            formContext._entity = { ...entityRef.current, ...entityChangesRef.current };
            formContext._originalEntity = { ...entity };

            if (props.isQuickCreate || props.formUniqueName) {
                // TODO: We should serialize the entity here,
                props.fireEvent("onDataChange", { ...formContext._entity });
            }
        }
    }, [entity, entityChanges]);

    useEffect(() => {
        setEntityId(props.entityId);
    }, [props.entityId]);

    useEffect(() => {
        props.fireEvent("__formNotificationsChanged", [...formNotificationsRef.current]);
    }, [formNotifications]);

    const getAllControls = (tabs: IForm.Tab[]): IForm.Control[] => {
        const controls: IForm.Control[] = [];

        for (const tab of tabs) {
            for (const column of tab.columns) {
                for (const section of column.sections) {
                    for (const row of section.rows) {
                        for (const cell of row.cells) {
                            if (cell.control) {
                                controls.push(cell.control);
                            }
                        }
                    }
                }
            }
            for (const row of tab.tabFooter?.rows ?? []) {
                for (const cell of row.cells) {
                    if (cell.control) {
                        controls.push(cell.control);
                    }
                }
            }
        }

        return controls;
    };

    const ribbonEnabled = () => {
        if (!props.entityName) {
            return false;
        }
        if (!props.enableRibbon) {
            return false;
        }
        return true;
    };

    const _isEditable = (tabs: IForm.Tab[]): boolean => {
        // TODO: Can we do this nicer? Use JPath
        for (const tab of tabs.filter(x => x.visible === true)) {
            for (const column of tab.columns) {
                for (const section of column.sections) {
                    for (const row of section.rows) {
                        for (const cell of row.cells) {
                            if (cell?.control?.type === FormControlType.Field &&
                                cell?.control?.disabled === false &&
                                cell?.control?.visible === true &&
                                cell?.control?.name?.indexOf("FormControl") === -1 &&
                                cell?.control?.name?.indexOf("TALXIS.PCF.Portal.Form") === -1) {
                                console.log(`Attribute ${cell.label} - ${cell.control.datafieldname} - ${cell.control.name} is editable!`);
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    };

    /**
     * Mapping lookups by reference
     */
    const mapLookups = (entity: ComponentFramework.WebApi.Entity) => {
        for (const key of Object.keys(entity)) {
            if (key.startsWith("_") && key.endsWith("_value")) {
                const logicalName = key.replace("_value", "").replace("_", "");
                const attribute = entityDefinitionRef.current?.Attributes?.find(x => x.LogicalName === logicalName);
                if (attribute?.AttributeType === "Lookup" || attribute?.AttributeType === "Owner" || attribute?.AttributeType === "Customer") {
                    if (entity[key]) {
                        const lookupValue: ComponentFramework.LookupValue = {
                            id: entity[key],
                            entityType: entity[`${key}@Microsoft.Dynamics.CRM.lookuplogicalname`],
                            name: entity[`${key}@OData.Community.Display.V1.FormattedValue`]
                        };
                        entity[logicalName] = [lookupValue];
                    }
                    else {
                        entity[logicalName] = [];
                    }
                    delete entity[key];
                    delete entity[`${key}@Microsoft.Dynamics.CRM.lookuplogicalname`];
                    delete entity[`${key}@OData.Community.Display.V1.FormattedValue`];
                }
            }
        }
    };

    const mapDateTimeAndDateOnly = (entityDefinition: IEntityDefinition, entity: ComponentFramework.WebApi.Entity) => {
        for (const key of Object.keys(entity)) {
            const attribute = entityDefinition.Attributes.find(x => x.LogicalName === key);
            if (attribute && attribute.AttributeType === "DateTime") {
                if (entity[key]) {
                    entity[key] = new Date(entity[key]);
                }
            }
        }
    };

    const mapMultiSelectPicklists = (entityDefinition: IEntityDefinition, entity: ComponentFramework.WebApi.Entity) => {
        for (const key of Object.keys(entity)) {
            const attribute = entityDefinition.Attributes.find(x => x.LogicalName === key);
            if (attribute && attribute.AttributeType === "Virtual" && attribute.AttributeTypeName?.Value === "MultiSelectPicklistType") {
                if (entity[key] && !Array.isArray(entity[key])) {
                    entity[key] = (entity[key] as string).split(",").map(x => parseInt(x));
                }
            }
        }
    };

    const mapPartyListLookups = async (entity: ComponentFramework.WebApi.Entity, partyListRelationshipName: string) => {
        const key = Object.keys(entity).find(x => x === partyListRelationshipName);
        if (key) {
            for (const record of entity[key]) {
                let lookupValue: ComponentFramework.LookupValue[] = [];
                const lookupEntityName = record[`_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname`];
                const lookupEntityDefinition = await EntityDefinition.getAsync(lookupEntityName);
                let associatedNavigationProperty = record[`_partyid_value@Microsoft.Dynamics.CRM.associatednavigationproperty`];
                const id = record["_partyid_value"];
                const formattedValue = record[`_partyid_value@OData.Community.Display.V1.FormattedValue`];
                const participationMask: number = record["participationtypemask"];
                const attributeLogicalName = ParticipationTypeMasktoType[participationMask];

                if (!associatedNavigationProperty) {
                    associatedNavigationProperty = lookupEntityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == lookupEntityName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName ??
                        lookupEntityDefinition.ManyToOneRelationships.find(x => x.ReferencedEntity == lookupEntityName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName;
                }
                if (entity[attributeLogicalName]) {
                    lookupValue = entity[attributeLogicalName];
                }
                lookupValue.push({
                    id: id,
                    entityType: lookupEntityDefinition.LogicalName,
                    name: formattedValue,
                });
                entity[attributeLogicalName] = lookupValue;
            }
        }
    };

    const getParticipationTypeMasks = (entityName: string, columnName?: string) => {
        const participationTypeMask: { [key: string]: { [key: string]: number } } = {
            appointment: { optionalattendees: 6, organizer: 7, requiredattendees: 5 },
            campaignactivity: { partners: 12, from: 1 }, // Not TESTED - Entity used in Dynamics365
            campaignresponse: { customer: 11, partner: 11, from: 11 }, // Not TESTED - Entity used in Dynamics365
            email: { bcc: 4, cc: 3, from: 1, to: 2 },
            fax: { from: 1, to: 2 },
            letter: { bcc: 4, from: 1, to: 2, cc: 3 },
            phonecall: { from: 1, to: 2 },
            recurringappointmentmaster: { optionalattendees: 6, organizer: 7, requiredattendees: 5 }, // Not tested - The recurrence pattern is not valid. Select a valid recurrence pattern. error when creating record
            serviceappointment: { customers: 11, resources: 10 }, // Not TESTED - Entity used in Dynamics365
            customEntity: { bcc: 4, cc: 3, customers: 11, from: 1, optionalattendees: 6, organizer: 7, partners: 12, requiredattendees: 5, resources: 10, to: 2 }
        };

        if (!Object.keys(participationTypeMask).find(x => x === entityName.toLocaleLowerCase()))
            entityName = "customEntity";

        if (columnName) {
            return participationTypeMask[entityName][columnName];
        } else {
            return participationTypeMask[entityName];
        }
    };

    const assemblePartyListObject = async (excludedPartyListAttribute: number) => {
        const partyListObject: IPartyListObject[] = [];
        const partyListAttributes = getParticipationTypeMasks(props.entityName) as { [key: string]: number };
        for (const attribute in partyListAttributes) {
            if (entityRef.current[attribute] && partyListAttributes[attribute] !== excludedPartyListAttribute) {
                const values = entityRef.current[attribute];
                for (const value of values) {
                    const lookupEntityName = value["entityType"];
                    const lookupEntityDefinition = await EntityDefinition.getAsync(lookupEntityName);
                    const associatedNavigationProperty = lookupEntityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == lookupEntityName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName ??
                        lookupEntityDefinition.ManyToOneRelationships.find(x => x.ReferencedEntity == lookupEntityName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName;
                    partyListObject.push({
                        "participationtypemask": partyListAttributes[attribute],
                        [`${associatedNavigationProperty}@odata.bind`]: `/${lookupEntityDefinition.EntitySetName}(${value["id"]})`
                    });
                }
            }
        }
        return partyListObject;
    };

    const getTranslation = (string: string, variables: {} = {}): string => {
        return props.getTranslatedString(string, variables);
    };

    const onKeyDownHandler = async (event: KeyboardEvent) => {
        // if control key(windows) or command key(iOS) + S key is clicked
        if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
            const formContext = xrmExecutionContextRef.current.getFormContext() as XrmFormContext;
            if (formContext) {
                event.preventDefault();
                window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                await formContext._saveForm();
                window.Xrm.Utility.closeProgressIndicator();
            }
        }
    };

    const _onRecordSelect = useCallback((controlId: string, executionContext: Xrm.Events.EventContext) => {
        const event = formRef.current?.events.find(x => x.control === controlId && x.name === "onrecordselect");
        if (event) {
            for (const handler of event.handlers) {
                const parameters: any[] = [];
                if (handler.passExecutionContext) {
                    parameters.push(executionContext);
                }
                executeFunctionByName(handler.functionName, iframeRef.current, parameters);
            }
        }
    }, [form]);

    const _onRecordsSelect = useCallback((controlId: string, executionContext: Xrm.Events.EventContext) => {
        const event = formRef.current?.events.find(x => x.control === controlId && x.name === "onrecordsselect");
        if (event) {
            for (const handler of event.handlers) {
                const parameters: any[] = [];
                if (handler.passExecutionContext) {
                    parameters.push(executionContext);
                }
                executeFunctionByName(handler.functionName, iframeRef.current, parameters);
            }
        }
    }, [form]);

    const _registerChildControl = useCallback((name: string, control: IChildControl) => {
        childControlsRef.current[name] = control;
    }, []);

    const _setEntityChanges = useCallback((entityChanges: ComponentFramework.WebApi.Entity) => {
        let changed = false;
        for (const [key, value] of Object.entries(entityChanges)) {
            if (value !== entityChangesRef.current[key]) {
                if (value === "") {
                    entityChanges[key] = null;
                }
                changed = true;
                break;
            }
        }
        if (changed) {
            entityChangesRef.current = { ...entityChangesRef.current, ...entityChanges };
            setEntityChanges(entityChangesRef.current);
        }
    }, [setEntityChanges]);

    useEffect(() => {
        if (tabsRef.current != null) {
            const editable = _isEditable(tabsRef.current);
            setIsEditable(editable);
        }
    }, [tabs]);

    useEffect(() => {
        if (currentTab) {
            if (form && currentTab.id !== stateValuesRef.current.previousTabId) {
                setVisitedSteps([...new Set([...visitedSteps, currentTab.id])]);
                const tabStateChangeEvents = form.events.filter(x => x.name == "tabstatechange");
                for (const onChangeEvent of tabStateChangeEvents) {
                    for (const handler of onChangeEvent.handlers) {
                        // TODO: Maybe load the library dynamically here instead at FormMapper
                        const parameters: any[] = [];
                        if (handler.passExecutionContext) {
                            parameters.push(xrmExecutionContextRef.current);
                        }
                        if (handler.parameters) {
                            parameters.push(handler.parameters);
                        }

                        try {
                            console.log(`Executing ${handler.functionName} function on ${form.id} form tabstatechange event.`);
                            // TODO: Pass other parameters as per handler.parameters
                            executeFunctionByName(handler.functionName, iframeRef.current, parameters);
                        }
                        catch (err) {
                            console.error(`Error in custom script: ${handler.functionName}`, err);
                        }
                    }
                }
            }

            props.fireEvent('__onFooterUpdate', {
                currentTab: tabsRef.current.find(tab => tab.id === stateValuesRef.current.currentTabId),
                executionContext: xrmExecutionContextRef.current
            });
            props.fireEvent('__onFormHeaderChanged', headerRef.current);
            stateValuesRef.current.previousTabId = currentTab.id;
        }
    }, [currentTab]);

    useEffect(() => {
        // As per https://www.debuggr.io/react-update-unmounted-component/
        let mounted = true;
        const initAsync = async () => {
            try {
                const entityDefinition = props.entityName ? await EntityDefinition.getAsync(props.entityName) : null;
                let expand: string = null;
                let partyListRelationshipName: string = null;
                // If entity contains partylist attributes
                if (entityDefinition?.IsActivity) {
                    const participationTypeMasks = getParticipationTypeMasks(props.entityName); // get participation masks for entity
                    partyListRelationshipName = entityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == props.entityName && x.ReferencingAttribute == "activityid")?.ReferencedEntityNavigationPropertyName;
                    expand = `?$expand=${partyListRelationshipName}($filter=Microsoft.Dynamics.CRM.In(PropertyName='participationtypemask',PropertyValues=[${Object.values(participationTypeMasks).map(x => { return `'${x}'`; })}]))`;
                }
                //There are two cases when data is not supposed to be loaded - new record form & dialog
                const recordData = (props.entityName && entityId) ? await window.Xrm.WebApi.retrieveRecord(props.entityName, entityId, expand) : {};

                const entityChangesFromExtraQs: ComponentFramework.WebApi.Entity = {};
                entityDefinitionRef.current = entityDefinition;
                mapLookups(recordData);
                mapDateTimeAndDateOnly(entityDefinition, recordData);
                mapMultiSelectPicklists(entityDefinition, recordData);
                if (entityDefinition?.IsActivity) {
                    await mapPartyListLookups(recordData, partyListRelationshipName);
                }

                const extraQsKeys = Object.keys(props.extraqs ?? []);

                // Update current recordData with extraQs passed from Page.
                for (const [property, value] of Object.entries(props.extraqs ?? [])) {
                    // We are in a dialog, so we don't set anything related to lookup from extraQs
                    if (!props.entityName) {
                        recordData[property] = value;
                        entityChangesFromExtraQs[property] = value;
                    }
                    else {
                        const attribute = entityDefinition.Attributes.find(x => x.LogicalName === property);

                        if (attribute) {
                            if (attribute?.AttributeType === "Lookup" || attribute?.AttributeType === "Owner" || attribute?.AttributeType === "Customer" || attribute?.AttributeType === "PartyList") {
                                const lookupValue: ComponentFramework.LookupValue = {
                                    id: sanitizeGuid(value as string),
                                    entityType: props.extraqs[`${property}type`] as string ?? attribute.Targets?.[0],
                                    name: props.extraqs[`${property}name`] as string
                                };
                                recordData[property] = [lookupValue];
                                entityChangesFromExtraQs[property] = [lookupValue];
                            }
                            else if (!attribute.AttributeOf) {
                                //parse Int if we know target attribute is integer or picklist
                                if (attribute.AttributeType === "Integer" || attribute.AttributeType === "Picklist" || attribute.AttributeType === "State" || attribute.AttributeType === "Status") {
                                    const integerValue: number = parseInt(value as string);
                                    recordData[property] = integerValue;
                                    entityChangesFromExtraQs[property] = integerValue;
                                }
                                else {
                                    recordData[property] = value;
                                    entityChangesFromExtraQs[property] = value;
                                }
                            }
                        }
                    }
                }
                const formMapper = new FormMapper(props.entityName, props.formId, props.formUniqueName);
                const _form = await formMapper.getForm();

                if (SCRIPT_ISOLATION) {
                    iframeRef.current = await IFrameFactory
                        .createIFrame(
                            {
                                context: window,
                                id: pageIdRef.current
                            });
                }

                for (const library of _form.libraries) {
                    await ScriptLoader.loadWebResourceLibraryAndDependencies(library.name, iframeRef.current);
                }

                for (const attribute of entityDefinition?.Attributes ?? []) {
                    attributeConfigurationRef.current[attribute.LogicalName] = {
                        customRequiredLevel: null,
                        entityRequiredLevel: attribute.RequiredLevel.Value,
                        isValid: true
                    };
                    if (attribute.AttributeTypeName.Value === "MultiSelectPicklistType") {
                        const optionsets = await OptionSetDefinition.getMultiSelectOptionSetAsync(props.entityName);
                        attributeConfigurationRef.current[attribute.LogicalName].options = optionsets.value.find(x => x.LogicalName === attribute.LogicalName)?.OptionSet.Options;
                    }
                    else if (attribute.AttributeType === "Picklist") {
                        const optionsets = await OptionSetDefinition.getAsync(props.entityName);
                        attributeConfigurationRef.current[attribute.LogicalName].options = optionsets.value.find(x => x.LogicalName === attribute.LogicalName)?.OptionSet.Options;
                    }

                    // Update boolean fields with default value, unless already provided by extraqs or already set in the record
                    if (attribute?.AttributeType === "Boolean" && !extraQsKeys.includes(attribute.LogicalName) && recordData[attribute.LogicalName] === undefined) {
                        const optionsets = await OptionSetDefinition.getTwoOptionsAsync(props.entityName);

                        recordData[attribute.LogicalName] = optionsets.value.find(x => x.LogicalName === attribute.LogicalName)?.DefaultValue;
                    }

                }
                // When form is dialog, we need to configure attributeConfiguration from id of controls
                // TODO: We should replace this with iterating through control elements in parsed _form instead of _form.formXml
                if (!props.entityName && !entityId) {
                    for (const control of _form.formXml.getElementsByTagName("control")) {
                        const id = control.getAttribute("id");
                        attributeConfigurationRef.current[id] = {
                            customRequiredLevel: null,
                            entityRequiredLevel: control.getAttribute("isrequired") === "true" ? "ApplicationRequired" : "None",
                            isValid: true
                        };
                    }
                }

                setAttributeConfiguration({ ...attributeConfigurationRef.current });

                const isSystemUserEntity = props.entityName === 'systemuser';
                // Systemuser entity doesn't have statecode nor statuscode, it has only TwoOption isdisabled field
                const stateFieldName = isSystemUserEntity ? 'isdisabled' : 'statecode';
                // Record is in not active state and form should be disabled
                // Implicit compare for comparing number with boolean
                if (props.entityName && props.entityId && recordData[stateFieldName] != 0) {
                    for (const tab of _form.tabs) {
                        for (const column of tab.columns) {
                            for (const section of column.sections) {
                                for (const row of section.rows) {
                                    for (const cell of row.cells) {
                                        if (cell.control) {
                                            cell.control.disabled = true;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    const optionSets = await OptionSetDefinition.getAsync(props.entityName);

                    const statusCode = optionSets.value.find(x => x.LogicalName == "statuscode")?.OptionSet.Options.find(x => x.Value === recordData["statuscode"]);

                    // Systemuser IsDisabled columns has value of true/false, but value in its optionset is defined as 1/0
                    const stateComparisonValue = isSystemUserEntity ? (recordData[stateFieldName] ? 1 : 0) : recordData[stateFieldName];
                    const stateCode = optionSets.value.find(x => x.LogicalName === stateFieldName)?.OptionSet.Options.find(x => x.Value === stateComparisonValue);

                    props.fireEvent("__setFormDisabled", { disabled: true, message: `${props.getTranslatedString("recordInactiveNotification", {})} ${LocalizeLabel(statusCode?.Label?.LocalizedLabels || stateCode.Label.LocalizedLabels)}` });
                }

                // TODO: Move this into a separate class.
                xrmExecutionContextRef.current = {
                    getFormContext() {
                        if (!this.formContext) {
                            const setEntityValue = (key: string, value: any) => {
                                const attribute = entityDefinitionRef.current?.Attributes.find(x => x.LogicalName === key);
                                let control: any;
                                if (!attribute) {
                                    control = this.formContext.getControl(key);
                                }
                                if (
                                    (attribute && (attribute.AttributeType === "Lookup" || attribute.AttributeType === "Owner" || attribute.AttributeType === "Customer")) ||
                                    // This handles case of dialogs where we currently don't have any attribute configuration set yet
                                    (entityDefinitionRef.current === null && isLookupSoft(value))
                                ) {
                                    if (value === null) {
                                        value = [];
                                    }
                                    else if (Array.isArray(value)) {
                                        const lookupValue: Xrm.LookupValue[] = cloneDeep(value);
                                        lookupValue.forEach(x => x.id = sanitizeGuid(x.id));
                                        value = lookupValue;
                                    }
                                }
                                else if (attribute && attribute.AttributeType === "DateTime") {
                                    let date = new Date(value);
                                    if (attribute.DateTimeBehavior.Value === "TimeZoneIndependent" || attribute.DateTimeBehavior.Value === "DateOnly") {
                                        const offset = UserSettingsDefinition.getUserSettings().getLocalTimeZoneOffset();
                                        date = new Date(date.getTime() + offset);
                                    }
                                    else if (attribute.DateTimeBehavior.Value === "UserLocal" && attribute.Format === "DateOnly") {
                                        let offset = UserSettingsDefinition.getUserSettings().getLocalTimeZoneOffset();

                                        if (offset < -1) {
                                            offset *= -1;
                                        }

                                        date = new Date(date.getTime() + offset);
                                        date.setHours(0);
                                    }

                                    value = date;
                                } else if (attribute && attribute.AttributeType === "Integer" && attribute.Format === "Duration") {
                                    value = Math.round(value);
                                }

                                // We update values on formContext first, because it is synchronous (JS code will see changes instantly)
                                entityChangesRef.current = { ...entityChangesRef.current, ...{ [key]: value } };
                                this.formContext._entity = { ...this.formContext._entity, ...entityChangesRef.current };

                                // TODO: Should we check mounted here?
                                setEntityChanges(entityChangesRef.current);
                            };
                            const setTabVisible = (name: string, visible: boolean) => {
                                const tab = tabsRef.current.find(x => x.name === name);
                                // Tab visibility being modified via reference, it is available in FormContext instantly.
                                tab!.visible = visible;

                                setTabs([...tabsRef.current]);

                                // Propagate visibility to sections as well because of client side validation
                                const sections = tab!.columns.flatMap(x => x.sections);
                                sections.forEach(section => setSectionVisible(name, section.name, visible));
                            };
                            const setSectionVisible = (tabName: string, name: string, visible: boolean) => {
                                const tab = tabsRef.current.find(x => x.name === tabName);
                                const section = tab!.columns.flatMap(x => x.sections).find(y => y.name === name);
                                // Section visibility being modified via reference, it is available in FormContext instantly.
                                section!.visible = visible;

                                setTabs([...tabsRef.current]);

                                // Propagate visibility to cells as well because of client side validation
                                const cells = section!.rows.flatMap(x => x.cells);
                                cells.filter(cell => cell.control?.id).forEach(cell => setCellVisible(tabName, name, cell.control.id, visible));
                            };
                            const setCellVisible = (tabName: string, sectionName: string, cellName: string, visible: boolean) => {
                                const tab = tabsRef.current.find(x => x.name === tabName);
                                const section = tab!.columns.flatMap(x => x.sections).find(y => y.name === sectionName);
                                const cell = section!.rows.flatMap(x => x.cells).find(y => y?.control?.id === cellName);
                                // Cell visibility being modified via reference, it is available in FormContext instantly.
                                cell!.visible = visible;
                                // the state of the cell's control is not updated, so we need to update it manually
                                // control visibility is checked in validateFormField()
                                if (cell!.control) {
                                    cell!.control!.visible = visible;
                                }

                                setTabs([...tabsRef.current]);
                            };
                            const setSectionLabel = (tabName: string, name: string, label: string) => {
                                const tab = tabsRef.current.find(x => x.name == tabName);
                                const section = tab.columns.find(x => x.sections.some(z => z.name === name)).sections.find(x => x.name === name);
                                // Section label being modified via reference, it is available in FormContext instantly.
                                section.label = label;

                                setTabs([...tabsRef.current]);
                            };
                            const setTabLabel = (name: string, label: string) => {
                                const tabs = (this.formContext.ui as XrmUi)._tabs;
                                const tab = tabs.current.find(x => x.name == name);
                                // Tab label being modified via reference, it is available in FormContext instantly.
                                const labelMatches = [...label.matchAll(/#(.*)#(.*)/g)];
                                const labelMatchResult = labelMatches.length === 1 ? labelMatches[0] : undefined;
                                tab.iconName = labelMatchResult && labelMatchResult.length > 2 ? labelMatchResult[1] : null;
                                tab.label = labelMatchResult && labelMatchResult.length > 2 ? labelMatchResult[2] : label;

                                // TODO: Should we check mounted here?
                                setTabs([...tabs.current]);
                            };
                            const updateTabs = (updatedTabs: IForm.Tab[]) => {
                                tabsRef.current = [...updatedTabs];

                                setTabs([...updatedTabs]);
                                setCurrentTab({ ...updatedTabs.find(x => x.id === stateValuesRef.current.currentTabId) });
                            };
                            const setTabFocus = (id: string) => {
                                const tab = tabsRef.current.find(x => x.id === id);
                                stateValuesRef.current.currentTabId = tab.id;
                                setCurrentTab({ ...tab });
                            };
                            const setRequiredLevel = (attributeName: string, level: Xrm.Attributes.RequirementLevel): void => {
                                const attributeConfiguration = attributeConfigurationRef.current[attributeName];
                                if (attributeConfiguration) {
                                    attributeConfiguration.customRequiredLevel = level;
                                }
                                else {
                                    throw new Error(`Attribute ${attributeName} not found!`);
                                }
                                setAttributeConfiguration({ ...attributeConfigurationRef.current });
                            };
                            const updateAttributeConfiguration = () => {
                                setAttributeConfiguration({ ...attributeConfigurationRef.current });
                            };
                            const refreshRibbon = () => {
                                const formContext = this.formContext;
                                if (ribbonEnabled()) {
                                    (async () => {
                                        ribbonRef.current.refresh();
                                    })();
                                }
                                props.fireEvent('__refreshRibbon', { formContext });
                            };
                            this.formContext = new XrmFormContext(
                                pageIdRef.current,
                                _form.id,
                                tabsRef,
                                headerRef,
                                setEntityValue,
                                setTabVisible,
                                setSectionVisible,
                                setCellVisible,
                                setSectionLabel,
                                setTabLabel,
                                setTabFocus,
                                updateTabs,
                                saveForm,
                                refreshForm,
                                childControlsRef,
                                onPostSaveRef,
                                onSaveRef,
                                stateValuesRef,
                                attributeConfigurationRef,
                                entityDefinitionRef,
                                entityChangesRef,
                                setRequiredLevel,
                                isFormValid,
                                updateAttributeConfiguration,
                                childFormContextsRef,
                                formNotificationsRef,
                                setFormNotifications,
                                refreshRibbon,
                                setShouldRefresh,
                                (control: IForm.Control) => {
                                    return validateFormField(control);
                                },
                                props.entityName,
                                entityId,
                                !!props.isDialog
                            );
                        }
                        return this.formContext;
                    },
                    getContext: () => { throw new Error("Not implemented in EventContext!"); },
                    getDepth: () => { throw new Error("Not implemented in EventContext!"); },
                    getEventSource: () => { throw new Error("Not implemented in EventContext!"); },
                    getSharedVariable<T>(key: string) {
                        throw new Error("Not implemented in EventContext!");
                    },
                    setSharedVariable<T>(key: string, value: T) {
                        throw new Error("Not implemented in EventContext!");
                    }
                };
                if (mounted) {
                    if (props.isMainTopLevel) {
                        window.Xrm.Page = {
                            data: {
                                //@ts-ignore - not implementing promise like
                                refresh: async (save: boolean) => {
                                    if (save) {
                                        await saveForm();
                                    }
                                    await refreshForm();
                                }
                            },
                            pageId: pageIdRef.current
                        };
                    }
                    setEntity(recordData);
                    entityChangesRef.current = entityChangesFromExtraQs;
                    setEntityChanges(entityChangesFromExtraQs);
                    entityRef.current = recordData;
                    if (Object.keys(previousEntityChangesRef.current).length === 0) previousEntityChangesRef.current = recordData;

                    const formContext = xrmExecutionContextRef.current.getFormContext() as XrmFormContext;
                    formContext._entity = recordData;
                    tabsRef.current = _form.tabs;
                    headerRef.current = _form.header;
                    props.isMainTopLevel && window.document.addEventListener('keydown', onKeyDownHandler);
                    ribbonRef.current = new FormRibbon(formContext, props.enableRibbon);
                    //passing initial formContext to render ribbon
                    props.fireEvent('__onFormUpdated', { formContext });
                    setTabs(_form.tabs);

                    //TODO: extract from portal
                    if (stateValuesRef.current.currentTabId) {
                        const tab = _form.tabs.find(x => x.id === stateValuesRef.current.currentTabId);
                        setCurrentTab(tab);
                    }
                    else if (props.extraqs && props.extraqs.talxis_setfocustotab && _form.tabs.some(x => x.name === props.extraqs.talxis_setfocustotab)) {
                        const tab = _form.tabs.find(x => x.name === props.extraqs.talxis_setfocustotab);
                        setCurrentTab(tab);
                        stateValuesRef.current.currentTabId = tab.id;
                        stateValuesRef.current.previousTabId = tab.id;
                    }
                    else {
                        const tab = _form.tabs.find(x => x.visible === true);
                        if (tab) {
                            setCurrentTab(tab);
                            stateValuesRef.current.currentTabId = tab.id;
                            stateValuesRef.current.previousTabId = tab.id;
                        }
                    }
                    if (_form.type === FormType.Dialog) {
                        setVisitedSteps([stateValuesRef.current.currentTabId]);
                    }
                    // Form is set as the last, because we trigger the onLoad event after the form is set.
                    setForm(_form);
                }
            }
            catch (err) {
                if (err && typeof err === "object" && 'code' in err && 'message' in err) {
                    if (err.code === WebApiErrors.RECORD_IS_UNAVAILABLE || err.code === WebApiErrors.SOMETHING_WENT_WRONG) {
                        await Xrm.Navigation.openErrorDialog({
                            message: err.message as string
                        });
                        history.goBack();
                    } else {
                        if (mounted) setError(err.message as any);
                    }
                } else {
                    if (mounted) setError(err as any);
                }
            }
        };

        if (props?.isNavbarLoaded) {
            initAsync();
        }

        return () => {
            if (mounted && props.isMainTopLevel) {
                window.Xrm.Page = undefined;
            }
            mounted = false;
            props.isMainTopLevel && window.document.removeEventListener("keydown", onKeyDownHandler);
            ribbonRef.current?.destroy();
            IFrameFactory.destroyIFrame(pageIdRef.current);
        };
    }, [entityId, props.formId, props.entityName, props.extraqs, props.isNavbarLoaded]);

    useEffect(() => {
        if (form) {
            const onChangeEvents = form.events.filter(x => x.name == "onchange");
            for (const onChangeEvent of onChangeEvents) {
                if (
                    (entityChanges[onChangeEvent.attribute] !== undefined && entityChanges[onChangeEvent.attribute] !== previousEntityChangesRef.current[onChangeEvent.attribute]) ||
                    (entityChanges[onChangeEvent.attribute] !== undefined && JSON.stringify(entityChanges[onChangeEvent.attribute]) !== JSON.stringify(previousEntityChangesRef.current[onChangeEvent.attribute]))
                ) {
                    for (const handler of onChangeEvent.handlers) {
                        // TODO: Maybe load the library dynamically here instead at FormMapper
                        const parameters: any[] = [];
                        if (handler.passExecutionContext) {
                            parameters.push(xrmExecutionContextRef.current);
                        }
                        if (handler.parameters) {
                            parameters.push(handler.parameters);
                        }

                        try {
                            console.log(`Executing ${handler.functionName} function on ${form.id} form onchange event.`);
                            // TODO: Pass other parameters as per handler.parameters
                            executeFunctionByName(handler.functionName, iframeRef.current, parameters);
                        }
                        catch (err) {
                            console.error(`Error in custom script: ${handler.functionName}`, err);
                        }
                    }
                }
            }
            previousEntityChangesRef.current = entityChanges;
        }
    }, [entityChanges]);

    useEffect(() => {
        // form property is only set once during initialization, so it shouldn't change during runtime, therefor we can consider this being `onload`
        if (form && onLoadExecutedRef.current === false) {
            const onLoadEvents = form.events.filter(x => x.name == "onload");
            for (const onLoadEvent of onLoadEvents) {
                for (const handler of onLoadEvent.handlers) {
                    // TODO: Maybe load the library dynamically here instead at FormMapper
                    const parameters: any[] = [];
                    if (handler.passExecutionContext) {
                        parameters.push(xrmExecutionContextRef.current);
                    }
                    if (handler.parameters) {
                        parameters.push(handler.parameters);
                    }

                    try {
                        console.log(`Executing ${handler.functionName} function on ${form.id} form onload event.`);
                        // TODO: Pass other parameters as per handler.parameters
                        executeFunctionByName(handler.functionName, iframeRef.current, parameters);
                    }
                    catch (err) {
                        console.error(`Error in custom script: ${handler.functionName}`, err);
                    }
                }
            }
            onLoadExecutedRef.current = true;
        }
        formRef.current = form;
    }, [form]);

    useEffect(() => {
        const formContext = xrmExecutionContextRef.current?.getFormContext();
        if (!formContext) return;
        props.fireEvent('__onFormUpdated', { formContext });
        if (formContext.data.getIsDirty()) {
            setFormUpdated(true);
            return;
        }
        setFormUpdated(false);
    }, [entityChanges]);

    const refreshForm = async (): Promise<void> => {
        props.fireEvent("__refreshForm", { entityId: entityId });
    };

    const isFormValid = (): boolean => {
        setValidate(true);

        console.groupCollapsed("Form Validation", props.formId ?? props.formUniqueName);

        let validationResult = true;

        const resultingEntity: ComponentFramework.WebApi.Entity = { ...entityRef.current, ...entityChangesRef.current };
        console.log("Entity", resultingEntity);

        for (const control of getAllControls(formRef.current.tabs)) {
            const result = validateFormField(control);
            if (!result) {
                validationResult = false;
                break;
            }
        }

        console.groupEnd();

        return validationResult;
    };

    const validateFormField = (control: IForm.Control): boolean => {
        const resultingEntity: ComponentFramework.WebApi.Entity = { ...entityRef.current, ...entityChangesRef.current };

        //check for valid field, control definiton is null or undefined in case of a Button
        if (control.definition?.manifest) {
            const attributeConfig = attributeConfigurationRef.current[control.datafieldname];
            const isRequired = getRequiredLevel(attributeConfig, control.isRequired);
            const primaryBoundField = Object.entries(control.bindings).find(x => x[1].isStatic === false);

            const manifestDefinition = control.definition.manifest.properties.find(x => x.name == primaryBoundField?.[0]);
            const type = manifestDefinition?.ofType ?? DataType.SingleLineText;

            const isValid = attributeConfig?.isValid;

            console.log(control.name, control.datafieldname, isRequired, type, isValid);

            if (!isFieldValid(isRequired, control.disabled, control.visible, type, resultingEntity, primaryBoundField[1].value, isValid)) {
                console.log("Invalid value!", control.name, control.datafieldname, isRequired, type);
                return false;
            }
        }

        return true;
    };

    // This is partially duplicate of isFormValid, and should be unified in future
    const isTabValid = (tab: IForm.Tab): boolean => {
        let validationResult = true;

        console.groupCollapsed("Tab Validation", props.formId ?? props.formUniqueName, tab);

        for (const control of getAllControls([tab])) {
            const result = validateFormField(control);
            if (!result) {
                validationResult = false;
                break;
            }
        }

        console.groupEnd();

        return validationResult;
    };

    const saveForm = async (): Promise<Xrm.CreateResponse | void> => {
        //propagate save to child forms
        const savePromises: Promise<void>[] = [];
        for (const key of Object.keys(childFormContextsRef.current)) {
            //@ts-ignore - not implementing promise like
            savePromises.push(childFormContextsRef.current[key].data.save());
        }
        await Promise.all(savePromises);
        setFormUpdated(false);
        let isDefaultPrevented = false;
        const isFormValidResult = isFormValid();
        if (isFormValidResult) {
            for (const handler of onSaveRef.current) {
                console.log("Executing onSave handler", handler);
                try {
                    const onSaveContext: Xrm.Events.SaveEventContext = {
                        ...xrmExecutionContextRef.current,
                        getEventArgs: (): Xrm.Events.SaveEventArguments => {
                            return {
                                getSaveMode: () => {
                                    throw new Error("Not implemented!");
                                },
                                isDefaultPrevented: () => {
                                    return isDefaultPrevented;
                                },
                                preventDefault: () => {
                                    isDefaultPrevented = true;
                                    console.log("onSave: Prevent default called!");
                                },
                                preventDefaultOnError: () => {
                                    throw new Error("Not implemented!");
                                }
                            };
                        },
                    };
                    const result = handler.apply(window, [onSaveContext]);
                    if (result && isPromise(result)) {
                        await result;
                    }
                }
                catch (err) {
                    console.error("Error while executing custom onPostSave handler!", err);
                }
            }
        }
        if (isFormValidResult && !isDefaultPrevented) {
            var saveResponse = await saveUpdatedFields();
            if (saveResponse === false) {
                setFormUpdated(true);
                window.Xrm.Navigation.openErrorDialog({
                    message: getTranslation("failedToSave")
                });
            }
            else if (saveResponse !== undefined) {
                if (typeof saveResponse === "object") {
                    // TODO: Update URL of the current open form unless the URL entityName is talxis_portalpage, use Xrm.Navigation.navigateTo
                    // This should also reflect in entityId because further changes will result in new records being created
                    const entityId = saveResponse.id;
                    // We don't want to make any URL changes when present on talxis_portalpage or when in Quick Create dialog because it will get closed once record is created
                    // also dont make any URL changes while child forms are being saved
                    if (data.entityName !== 'talxis_portalpage' && props.isQuickCreate !== true && props.isMainTopLevel) {
                        data.entityId = entityId;
                        const url = `${window.location.pathname}?data=${encodeURIComponent(JSON.stringify(data))}`;
                        history.replace(url);
                    }
                    setEntityId(entityId);
                    xrmExecutionContextRef.current.formContext._entityId = entityId;
                    entityRef.current[entityDefinitionRef.current.PrimaryIdAttribute] = entityId;
                }
                for (const handler of onPostSaveRef.current) {
                    console.log("Executing onPostSave handler", handler);
                    try {
                        handler.apply(window, [xrmExecutionContextRef.current]);
                    }
                    catch (err) {
                        console.error("Error while executing custom onPostSave handler!", err);
                    }
                }
                setEntity({ ...entityRef.current, ...entityChangesRef.current });
                entityRef.current = { ...entityRef.current, ...entityChangesRef.current };
                setEntityChanges({});
                entityChangesRef.current = {};
                // Only return the response if it is a create operation
                if (saveResponse !== true) {
                    if (props.isDialog && !props.isQuickCreate) {
                        xrmExecutionContextRef.current.formContext._isDialogNewRecord = true;
                        props.fireEvent("onCreateCallback", { isNewRecord: true, entityId: saveResponse.id });
                    }
                    return saveResponse;
                }
                // If it's just update operation, also return entity reference so we can safely close the dialog
                // TODO: This should be reworked to throw when error occurs or return true/false when update
                else {
                    return {
                        entityType: props.entityName,
                        id: entityId
                    };
                }
            }
        }
        else {
            console.log(`Skipped form saving, isFormValid: ${isFormValidResult}, isDefaultPrevented: ${isDefaultPrevented}`);
            if (!isFormValidResult) {
                Xrm.Navigation.openAlertDialog({
                    text: getTranslation("missingRequired"),
                    title: getTranslation("savingError"),
                    confirmButtonLabel: "OK"
                });
            }
            setFormUpdated(true);
        }
    };
    interface IPartyListObject {
        [key: string]: string | number;
    }

    const handleFailedSaveResponse = async (response: Xrm.ExecuteResponse): Promise<void> => {
        const error = (await response.json()).error;
        const message = getTranslation(error.code);

        window.Xrm.Navigation.openErrorDialog({
            errorCode: error.code,
            message: (message && message !== error.code.toString()) ? message : getTranslation("failedToSave"),
            details: error.message,
        });
    };

    const handleSaveException = async (error: XrmWebApiException | any): Promise<Xrm.CreateResponse | void> => {
        // making sure there's no progress indicator blocking the screen
        Xrm.Utility.closeProgressIndicator();

        if (error instanceof XrmWebApiException) {
            switch (error.errorCode) {
                case WebApiErrors.DUPLICATE_ERROR:
                    // @ts-ignore - openDialog is not part of Xrm types
                    const dialogResult = await Xrm.Navigation.openDialog("talxis_duplicatedetectiondialog", {
                        position: 1,
                        height: 500,
                        width: 500
                    }, {
                        talxis_entityname: props.entityName,
                        talxis_data: JSON.stringify(entityChanges),
                        talxis_recordidofrecordtobemerged: null
                    });
                    const mergedRecordId: string = dialogResult["parameters"]["talxis_mergedrecordid"];
                    if (mergedRecordId !== null) {
                        return {
                            entityType: props.entityName,
                            id: mergedRecordId ?? ""
                        };
                    }
                    break;
                // the following two cases are caused by alternate key constraint violation
                // the reason there are two different error codes is because of the localization of the user
                // 1033 (EN) returns different code and message then 1029 (CZ)
                // https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4078/Differences?anchor=duplicate-keys-detection
                case WebApiErrors.DUPLICATE_RECORD:
                case WebApiErrors.DUPLICATE_RECORD_ENTITY_KEY:
                    const duplicateMessage = getTranslation('duplicateAlternateKey');
                    await Xrm.Navigation.openErrorDialog({
                        errorCode: error.errorCode,
                        message: (duplicateMessage && duplicateMessage !== error.errorCode.toString()) ? duplicateMessage : getTranslation("failedToSave"),
                        details: error.message as string,
                    });
                    break;
                default:
                    const defaultMessage = getTranslation(error.errorCode.toString());
                    await Xrm.Navigation.openErrorDialog({
                        errorCode: error.errorCode as number,
                        message: (defaultMessage && defaultMessage !== error.errorCode.toString()) ? defaultMessage : getTranslation("failedToSave"),
                        details: error.message as string,
                    });
                    break;
            }
        }
        else {
            // default error handling
            await Xrm.Navigation.openErrorDialog({
                errorCode: -1,
                message: getTranslation("failedToSave"),
                details: JSON.stringify(error),
            });
        }
    };

    const saveUpdatedFields = async (): Promise<boolean | Xrm.CreateResponse> => {
        try {
            const entityChanges = { ...entityChangesRef.current };
            const keys = Object.keys(entityChanges);
            const entityDefinition = await EntityDefinition.getAsync(props.entityName);
            for (const key of keys) {
                const attribute = entityDefinition.Attributes.find(x => x.LogicalName === key);
                if (attribute.AttributeType === "Lookup" || attribute.AttributeType === "Owner" || attribute.AttributeType === "Customer") {
                    if (entityChanges[key] !== null) {
                        let referencingAttribute = key;
                        const values: ComponentFramework.LookupValue[] = entityChanges[key];
                        if (values?.length > 0) {
                            if (values.length > 1) {
                                throw new Error("Multiple values passed to save for lookup, this is unsupported!");
                            }
                            const lookupLogicalName = values[0].entityType;
                            const lookupTargetEntityDefinition = await EntityDefinition.getAsync(lookupLogicalName);
                            // This is a workaround where Dataverse doesn't seem to return OneToManyRelationships properly - eg. contact and parentcustomerid, _contact returns in OneToMany whereas _account returns in ManyToOne (with OneToMany type)
                            referencingAttribute = entityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == lookupLogicalName && x.ReferencingAttribute == referencingAttribute)?.ReferencingEntityNavigationPropertyName ??
                                entityDefinition.ManyToOneRelationships.find(x => x.ReferencedEntity == lookupLogicalName && x.ReferencingAttribute == referencingAttribute)?.ReferencingEntityNavigationPropertyName;
                            // Fixes edge case around ownerid which doesn't map to standard fields ownerid_systemuser but owninguser
                            if (key === "ownerid") {
                                referencingAttribute = key;
                            }
                            entityChanges[`${referencingAttribute}@odata.bind`] = `/${lookupTargetEntityDefinition.EntitySetName}(${values[0].id})`;
                            entityChanges[`${referencingAttribute}@OData.Community.Display.V1.FormattedValue`] = values[0].name;
                        }
                        else {
                            if (attribute.Targets?.length > 1) {
                                // Polymorphic lookups need to be referenced via _x_value
                                referencingAttribute = `_${referencingAttribute}_value`;
                            }
                            entityChanges[`${referencingAttribute}@odata.bind`] = null;
                        }
                        delete entityChanges[key];
                    }
                }
                if (attribute.AttributeType === "DateTime") {
                    if (entityChanges[key]) {
                        if (attribute.DateTimeBehavior.Value === "DateOnly") {
                            entityChanges[key] = entityChanges[key].toISOString().split('T')[0];
                        } else {
                            entityChanges[key] = entityChanges[key].toISOString();
                        }
                        // Lowest date supported by CDS: https://learn.microsoft.com/en-us/previous-versions/dynamicscrm-2016/developers-guide/dn996866(v=crm.8)?redirectedfrom=MSDN
                        if (new Date(entityChanges[key]).getTime() < new Date('1753-01-01T00:00:00.000Z').getTime()) {
                            throw new Error(`DateTime is less than minumum value supported by Crm. Actual value: ${new Date(entityChanges[key]).toLocaleDateString()}, Minimum value supported: 01/01/1753 00:00:00`);
                        }
                    }
                }
                if (attribute.AttributeType === "PartyList") {
                    const values: ComponentFramework.LookupValue[] = entityChanges[key];
                    const participationTypeMask = getParticipationTypeMasks(props.entityName, attribute.LogicalName);
                    const referencedRelationshipName = entityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == props.entityName && x.ReferencingAttribute == "activityid")?.ReferencedEntityNavigationPropertyName;
                    let partyListObject: IPartyListObject[] = [];
                    if (entityChanges[referencedRelationshipName]) {
                        partyListObject = [...(entityChanges[referencedRelationshipName].filter((x: IPartyListObject) => x["participationtypemask"] !== participationTypeMask))];
                    }
                    else {
                        partyListObject = [...await assemblePartyListObject(participationTypeMask as number)];
                    }
                    for (const value of values) {
                        const lookupLogicalName = value.entityType;
                        const lookupTargetEntityDefinition = await EntityDefinition.getAsync(lookupLogicalName);
                        const referencingAttribute: string = lookupTargetEntityDefinition.OneToManyRelationships.find(x => x.ReferencedEntity == lookupLogicalName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName ??
                            entityDefinition.ManyToOneRelationships.find(x => x.ReferencedEntity == lookupLogicalName && x.ReferencingAttribute == "partyid")?.ReferencingEntityNavigationPropertyName;
                        partyListObject.push({
                            "participationtypemask": participationTypeMask as number,
                            [`${referencingAttribute}@odata.bind`]: `/${lookupTargetEntityDefinition.EntitySetName}(${value.id})`
                        });
                    }
                    entityChanges[referencedRelationshipName] = partyListObject;
                    delete entityChanges[key];
                }
                if (attribute.AttributeTypeName.Value === "FileType") {
                    delete entityChanges[key];
                }
                if (Array.isArray(entityChanges[key])) entityChanges[key] = entityChanges[key].join();
            }
            if (entityId) {
                const updateRequest: IUpdateRequest = {
                    etn: props.entityName,
                    id: entityId,
                    payload: entityChanges,
                    suppressDupeDetection: false,
                    getMetadata: function () { return { boundParameter: null, parameterTypes: {}, operationType: 2, operationName: "Update" }; }
                };

                try {
                    const response = await Xrm.WebApi.online.execute(updateRequest);
                    if (!response.ok) {
                        handleFailedSaveResponse(response);
                        return;
                    }
                    return true;
                } catch (error) {
                    handleSaveException(error);
                }
            }
            else {
                const createRequest: ICreateRequest = {
                    etn: props.entityName,
                    payload: entityChanges,
                    suppressDupeDetection: false,
                    getMetadata: function () { return { boundParameter: null, parameterTypes: {}, operationType: 2, operationName: "Create" }; }
                };

                try {
                    const response = await Xrm.WebApi.online.execute(createRequest);
                    if (!response.ok) {
                        handleFailedSaveResponse(response);
                        return;
                    }
                    const entityId = response.headers.get("OData-EntityId").replace(/(^.*\(|\).*$)/g, '');

                    return {
                        entityType: props.entityName,
                        id: entityId
                    };
                } catch (error) {
                    handleSaveException(error);
                }
            }
        }
        catch {
            return false;
        }
    };

    const changeTab = (selectedTab: IForm.Tab) => {
        stateValuesRef.current.currentTabId = selectedTab.id;
        setCurrentTab(selectedTab);
    };

    const getSectionBodyStyles = (section: IForm.Section) => {
        let className = 'TALXIS__form__section__body';
        return `${className} ${mergeStyles({
            gridTemplateColumns: `repeat(${section.numOfColumns}, minmax(0, 1fr))`,
        })}`;
    };

    const getColumnsStyles = (columns: IForm.Column[]) => {
        let className = 'TALXIS__form__columns';
        const fractions = columns.map(x => x.width).join(' ').replaceAll("%", "fr");
        return `${className} ${mergeStyles({
            gridTemplateColumns: fractions
        })}`;
    };

    const getVisibleSections = (column: IForm.Column, isMobile: boolean) => {
        const visibleSections = [];

        for (const section of column.sections) {
            const hasVisibleControl = section.rows.some(
                row => row.cells.some(cell => cell.control?.visible === true)
            );
            if (hasVisibleControl && section.visible && (!isMobile || isMobile && section.availableForPhone)) {
                visibleSections.push(section);
            }
        }
        return visibleSections;
    };

    const getVisibleRows = (section: IForm.Section, isMobile: boolean) => {
        const visibleRows = [];
        for (const row of section.rows) {
            for (const cell of row.cells) {
                if (cell.control?.visible === true && (!isMobile || isMobile && cell.availableForPhone)) {
                    visibleRows.push(row);
                    // we only need to add the row once, so we can break here
                    break;
                }
            }
        }
        return visibleRows;
    };

    const getFormStyles = () => {
        return mergeStyles({
            ':global(.TALXIS__portal__content > main > div > div > div > div > .TALXIS__form)': {
                boxShadow: theme.semanticColors.cardShadow
            },
            '&, .TALXIS__form__section': {
                backgroundColor: theme.semanticColors.bodyBackground,
                borderRadius: 5,
                '.TALXIS__form__section__header': {
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
                    paddingBottom: 10
                }
            },
            '@media (max-width: 768px)': {
                '.TALXIS__form__section': {
                    borderRadius: 0,
                    '.TALXIS__form__section__header': {
                        borderRadius: 0
                    }
                }
            }
        });
    };
    const getRibbonWrapperStyles = () => {
        return `${mergeStyles({
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 1,
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            '>div:first-child': {
                flexGrow: 1,
                height: 44,
                minWidth: 0
            },
            '>span': {
                fontWeight: 600,
                color: 'grey',
                display: 'block',
                textAlign: 'right'
            },
            '.TALXIS__ribbon': {
                borderBottom: 'none'
            }
        })}`;
    };

    const getFormTabWrapperStyles = () => {
        return `TALXIS__form__tab-wrapper ${mergeStyles({
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            marginBottom: 15,
            justifyContent: 'flex-end',
            '.TALXIS__form__tab': {
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0
            },
            '&:empty': {
                display: 'none'
            }
        })}`;
    };

    if (error) {
        // TODO: We should probably handle this more gracefully than just throwing errors around.
        throw error;
    }

    const areAllControlsWithinSectionEnabled = (section: IForm.Section): boolean => {
        return !section?.rows?.find(row => row?.cells?.find(cell => cell?.control?.disabled === true));
    };

    const provider = useMemo((): IFormContext => {
        const context = {
            entityId: entityId,
            entityName: props.entityName,
            entity: entity,
            entityChanges: entityChanges,
            formUniqueName: props.formUniqueName,
            xrmExecutionContext: xrmExecutionContextRef.current,
            setEntityChanges: _setEntityChanges,
            onRecordSelect: _onRecordSelect,
            onRecordsSelect: _onRecordsSelect,
            registerChildControl: _registerChildControl,
            isMobile: isMobile,
            validate: validate,
            attributeConfiguration: { ...attributeConfigurationRef.current },
            shouldRerender: shouldRefresh,
            pageId: pageIdRef.current,
        };
        props.fireEvent('__onFormProviderUpdated', context);
        return context;

    }, [entityId, props.entityName, entity, entityChanges, attributeConfiguration, form, validate, shouldRefresh]);

    return (
        <FormContext.Provider value={provider}>
            <section ref={ref} data-id={props.formId} className={`TALXIS__form ${getFormStyles()}`}>
                <RibbonController
                    ribbon={ribbonRef.current}
                    hasUnsavedChanges={formUpdated}
                    forceHidden={!ribbonEnabled()} />
                {(!form) &&
                    <FormLoading
                        style={{
                            '--talxis-ribbon-bodyBackground': 'white',
                            '--talxis-ribbon-bodyDivider': 'transparent',
                            '--talxis-main-bodyBackgroundMain': 'white'
                        } as React.CSSProperties}
                    />
                }
                {form &&
                    <>
                        <div className={getFormTabWrapperStyles()}>
                            {form.type !== FormType.Dialog &&
                                <>
                                    {((!currentTab || currentTab?.showLabel) || (isEditable && !props.isQuickCreate)) &&
                                        <>
                                            <Tabs
                                                tabs={(!currentTab && tabs) || currentTab?.showLabel ? tabs : []}
                                                data-updated={formUpdated}
                                                currentTab={currentTab}
                                                onTabChange={changeTab}
                                            />
                                            {props.isMainTopLevel && !isPortalPage &&
                                                <IconButton
                                                    onClick={() => {
                                                        props.fireEvent("__toggleHeader", {});
                                                    }}
                                                    iconProps={{ iconName: 'DoubleChevronUp' }} />
                                            }
                                        </>
                                    }
                                </>
                            }
                            {form.type === FormType.Dialog &&
                                <>
                                    {!tabs?.find(x => !x.visible || !x.showLabel) &&
                                        <div style={{ width: '100%' }}>
                                            <Multistage
                                                currentStageKey={currentTab.id}
                                                onStageChange={(nextStageKey: string, goesToPreviousStage: boolean) => {
                                                    changeTab(tabs.find(x => x.id === nextStageKey));
                                                }}
                                                stageStates={tabs.map((x, index) => {
                                                    let isContinueEnabled = true;
                                                    if (index > 0) {
                                                        const previousTabControls = tabs[index - 1].tabFooter.rows.flatMap(x => x.cells).flatMap(x => x.control);
                                                        isContinueEnabled = previousTabControls.find(y => y.id.startsWith("continue") && y.disabled === false) !== undefined;
                                                    }
                                                    const hasTabBeenVisited = visitedSteps.includes(x.id);
                                                    const isTabValidResult = isContinueEnabled && hasTabBeenVisited ? isTabValid(x) : true;

                                                    let stageValue = StageValue.inactive;
                                                    if (isTabValidResult === false && isContinueEnabled) {
                                                        stageValue = StageValue.invalid;
                                                    }
                                                    else if (isTabValidResult && isContinueEnabled && hasTabBeenVisited) {
                                                        stageValue = StageValue.valid;
                                                    }
                                                    else if (isContinueEnabled && !hasTabBeenVisited) {
                                                        stageValue = StageValue.in_progress;
                                                    }
                                                    return {
                                                        stageKey: x.id,
                                                        value: stageValue
                                                    };
                                                })}
                                                stages={tabs.map(x => ({ key: x.id, label: x.label, onRender: () => <></> }))}
                                                footerProps={{
                                                    onRender: (props) =>
                                                        <></>
                                                }}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </>
                }
                {currentTab &&
                    <div className='TALXIS__form__tab-content' data-content-type={currentTab.contentType}>
                        <div className={getColumnsStyles(currentTab?.columns)}>
                            {currentTab?.columns.map(column =>
                                <div key={column.id} className='TALXIS__form__column'>
                                    {getVisibleSections(column, isMobile).map(section => (
                                        <div key={section.id} className='TALXIS__form__section' data-id={section.name} style={(() => {
                                            const allControlsWithinSectionEnabled = areAllControlsWithinSectionEnabled(section);
                                            return {
                                                '--cell-display-mode': section.celllabelposition === 'Top' ? 'block' : 'flex',
                                                '--cell-label-width': section.celllabelposition === 'Top' ? 'initial' : '150px',
                                                '--cell-lock-display': allControlsWithinSectionEnabled ? 'none' : 'inline-block',
                                                '--cell-control-lock-margin': allControlsWithinSectionEnabled ? '0' : '18px'
                                            } as React.CSSProperties;
                                        })()}>
                                            {section.showLabel && section.label && currentTab.contentType !== 'singleComponent' &&
                                                <div className='TALXIS__form__section__header'>
                                                    {section.label &&
                                                        <Text variant='mediumPlus' data-id={`form-sectionHeader-${section.name}`}>{section.label}</Text>
                                                    }
                                                </div>
                                            }
                                            <div className={getSectionBodyStyles(section)}>
                                                {getVisibleRows(section, isMobile).map((row, i) => row.cells.map((cell) => <Cell
                                                    index={i}
                                                    tabContentType={currentTab.contentType}
                                                    key={cell.id}
                                                    cell={cell}
                                                    onFormUpdated={(arg) => {
                                                        childFormContextsRef.current[cell.control.datafieldname] = arg.formContext;
                                                        props.fireEvent('__onFormUpdated', {
                                                            formContext: xrmExecutionContextRef.current.getFormContext()
                                                        });
                                                    }} />))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                }
            </section>
        </FormContext.Provider>
    );
};