import React, { useEffect, useImperativeHandle, useState, useRef } from "react";
import styles from './BotProtectionVerification.module.css';
import { DialogContent, Modal } from "@fluentui/react";

export interface BotProtectionVerificationProps {
    tokenServiceApi: string;
}

const CLOCK_SKEW = 2 * 60 * 1000;

interface IBotVerificationToken {
    access_token: string;
    expires_in: number;
    expires_on: Date;
}

export interface BotProtectionVerificationRef {
    getVerificationToken: () => Promise<string>;
}

const BotProtectionVerification = React.forwardRef<BotProtectionVerificationRef, BotProtectionVerificationProps>((props, ref) => {
    const [showChallenge, setShowChallenge] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const renderedRef = useRef<boolean>(false);

    const explicitVerificationRequestedRef = useRef<boolean>(false);
    const interactionRequiredRef = useRef<boolean>(false);

    const botVerificationTokenRef = useRef<IBotVerificationToken>(null);
    const verificationInProgressPromiseRef = useRef<Promise<void>>(null);
    const verificationInProgressRef = useRef<boolean>(false);

    const intervalIdRef = useRef<number>(null);
    const loadingTimeoutIdRef = useRef<number>(null);

    useImperativeHandle(ref, () => ({
        getVerificationToken
    }), []);

    const initializeVerification = () => {
        let resolvePromise: (value: void | PromiseLike<void>) => void;
        let rejectPromise: (reason: any) => void;

        loadingTimeoutIdRef.current = window.setTimeout(async () => {
            await Xrm.Navigation.openErrorDialog({
                message: window.TALXIS.Portal.Translations.getLocalizedString('components/utilities/BotProtectionVerification/LoadError'),
                details: "timeout"
            });

            window.location.reload();
        }, 5 * 1000);

        interactionRequiredRef.current = false;
        verificationInProgressRef.current = true;

        verificationInProgressPromiseRef.current = new Promise<void>((resolve, reject) => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        iframeRef.current.src = getRedirectUrl();

        monitorIframeForHash(iframeRef.current).then(async (hash) => {
            const params = new URLSearchParams(hash.substring(1));

            if (params.get("error")) {
                const error = params.get("error");
                const errorDescription = params.get("error_description");

                // TODO: We should enable retry in a smart way to either reject existing pending promises or let them resolve with the new token, but refresh is the safest option right now
                await Xrm.Navigation.openErrorDialog({
                    message: window.TALXIS.Portal.Translations.getLocalizedString('components/utilities/BotProtectionVerification/Error'),
                    details: "Bot protection error: " + error + " - " + errorDescription
                });
                window.location.reload();
            }
            else {
                const expiresOn = new Date();
                expiresOn.setSeconds(expiresOn.getSeconds() + parseInt(params.get("expires_in")));
                botVerificationTokenRef.current = {
                    access_token: params.get("access_token"),
                    expires_in: parseInt(params.get("expires_in")),
                    expires_on: expiresOn
                };
                setShowChallenge(false);
                verificationInProgressRef.current = false;
                resolvePromise();
            }
        });

        return verificationInProgressPromiseRef.current;
    };

    const monitorIframeForHash = (iframe: HTMLIFrameElement): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            if (intervalIdRef.current) {
                // TODO: Reject the previous promise here
                window.clearInterval(intervalIdRef.current);
            }
            intervalIdRef.current = window.setInterval(() => {
                let href: string = "";
                const contentWindow = iframe.contentWindow;
                try {
                    href = contentWindow.location.href;
                } catch (e) { }

                if (!href || href === "about:blank") {
                    return;
                }

                window.clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
                resolve(iframe.contentWindow.location.hash);
            }, 100);
        });
    };

    const getVerificationToken = async (force: boolean = false): Promise<string> => {
        explicitVerificationRequestedRef.current = true;

        const isTokenValid = botVerificationTokenRef.current && botVerificationTokenRef.current.expires_on.getTime() - CLOCK_SKEW > new Date().getTime();

        if (!verificationInProgressPromiseRef.current || force || (!isTokenValid && !verificationInProgressRef.current)) {
            verificationInProgressPromiseRef.current = initializeVerification();
        }

        await verificationInProgressPromiseRef.current;

        return botVerificationTokenRef.current.access_token;
    };

    const getRedirectUrl = () => {
        return props.tokenServiceApi + "/turnstile/v1.0/challenge?redirect_uri=" + window.location.origin + "/shared/blank.html"; //&debug=interact";
    };

    const receiveMessage = async (event: MessageEvent) => {
        if (event.origin !== props.tokenServiceApi) {
            return;
        }

        switch (event.data.type) {
            case "bot_protection_error":
                verificationInProgressRef.current = false;

                // TODO: We should enable retry in a smart way to either reject existing pending promises or let them resolve with the new token, but refresh is the safest option right now
                await Xrm.Navigation.openErrorDialog({
                    message: window.TALXIS.Portal.Translations.getLocalizedString('components/utilities/BotProtectionVerification/Error'),
                    details: "Bot protection error: " + event.data.error
                });
                window.location.reload();
                break;
            case "bot_protection_interaction_start":
                interactionRequiredRef.current = true;
                if (explicitVerificationRequestedRef.current) {
                    setShowChallenge(true);
                }
                break;
            case "bot_protection_interaction_end":
                verificationInProgressRef.current = false;
                break;
            case "bot_protection_loaded":
                if (loadingTimeoutIdRef.current) {
                    window.clearTimeout(loadingTimeoutIdRef.current);
                }
                break;
        }
    };

    useEffect(() => {
        if (!containerRef.current || renderedRef.current) {
            return;
        }

        renderedRef.current = true;

        iframeRef.current = document.createElement("iframe");
        // TODO: Attempt to detect whether the iframe has loaded or failed to load - this can be done by timer and waiting for postMessage to send ready or something since we are cross-origin (or enable cross-origin)

        containerRef.current.appendChild(iframeRef.current);

        verificationInProgressPromiseRef.current = initializeVerification();
    }, [containerRef.current]);

    useEffect(() => {
        window.addEventListener("message", receiveMessage);

        return () => {
            window.removeEventListener("message", receiveMessage);
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={true}
                styles={{ root: { display: showChallenge ? null : "none" } }}
                isBlocking={true}
                className={styles.root}
            >
                <DialogContent
                    title={window.TALXIS.Portal.Translations.getLocalizedString("components/utilities/BotProtectionVerification/Title")}
                    subText={window.TALXIS.Portal.Translations.getLocalizedString("components/utilities/BotProtectionVerification/Desription")}
                    className={styles.dialog}
                >
                    <div ref={containerRef} id="botProtectionIframeContainer"></div>
                </DialogContent>
            </Modal>
            {/*
                // Dialog use is blocked by https://github.com/microsoft/fluentui/issues/30701
                <Dialog
                    title={window.TALXIS.Portal.Translations.getLocalizedString("components/utilities/BotProtectionVerification/Title")}
                    subText={window.TALXIS.Portal.Translations.getLocalizedString("components/utilities/BotProtectionVerification/Desription")}
                    className={styles.root} minWidth={365} styles={{ root: { display: showChallenge ? null : "none" } }} hidden={false}
                >
                    <div ref={containerRef} id="botProtectionIframeContainer"></div>
                </Dialog>
            */}
        </div >
    );
});

export default BotProtectionVerification;